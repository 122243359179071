import { types } from "../constants/lucky";

const initialState = {
  list: {
    data: [],
    loading: false,
    error: null,
    page: 1,
    totalPage: null,
  },
  rewards: {
    data: [],
    loading: false,
    error: null,
  },
  onDelete: {
    loading: false,
    error: null,
  },
  onCreate: {
    loading: false,
    error: null,
  },
  onUpdate: {
    loading: false,
    error: null,
  },
  detail: {
    data: {},
    loading: false,
    error: null,
  },
  game: {
    data: [],
    loading: false,
    error: null,
  },
};

const lucky = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LUCKY_LOADING:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
      };
    case types.GET_LUCKY_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: "",
          data: action.data,
          totalPage: parseInt(action.totalPage) || state.list.totalPage,
          page: action.page,
        },
      };
    case types.GET_LUCKY_FAILED:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: action.message,
        },
      };
    case types.GET_LIST_SPIN_LOADING:
      return {
        ...state,
        game: {
          ...state.game,
          loading: true,
        },
      };
    case types.GET_LIST_SPIN_SUCCESS:
      return {
        ...state,
        game: {
          ...state.game,
          loading: false,
          error: "",
          data: action.data,
        },
      };
    case types.GET_LIST_SPIN_FAILED:
      return {
        ...state,
        game: {
          ...state.game,
          loading: false,
          error: action.message,
        },
      };
    case types.GET_REWARDS_LOADING:
      return {
        ...state,
        rewards: {
          ...state.rewards,
          loading: true,
        },
      };
    case types.GET_REWARDS_SUCCESS:
      return {
        ...state,
        rewards: {
          ...state.rewards,
          loading: false,
          error: "",
          data: action.data,
        },
      };
    case types.GET_REWARDS_FAILED:
      return {
        ...state,
        rewards: {
          ...state.rewards,
          loading: false,
          error: action.message,
          data: [],
        },
      };
    case types.DELETE_LUCKY_LOADING:
      return {
        ...state,
        onDelete: {
          ...state.onDelete,
          loading: true,
        },
      };
    case types.DELETE_LUCKY_SUCCESS:
      return {
        ...state,
        onDelete: {
          ...state.onDelete,
          loading: false,
          error: "",
        },
      };
    case types.DELETE_LUCKY_FAILED:
      return {
        ...state,
        onDelete: {
          ...state.onDelete,
          loading: false,
          error: action.message,
        },
      };

    case types.CREATE_LUCKY_LOADING:
      return {
        ...state,
        onCreate: {
          ...state.onCreate,
          loading: true,
        },
      };
    case types.CREATE_LUCKY_SUCCESS:
      return {
        ...state,
        onCreate: {
          ...state.onCreate,
          loading: false,
          error: "",
        },
      };
    case types.CREATE_LUCKY_FAILED:
      return {
        ...state,
        onCreate: {
          ...state.onCreate,
          loading: false,
          error: action.message,
        },
      };
    case types.UPDATE_LUCKY_LOADING:
      return {
        ...state,
        onUpdate: {
          ...state.onUpdate,
          loading: true,
        },
      };
    case types.UPDATE_LUCKY_SUCCESS:
      return {
        ...state,
        onUpdate: {
          ...state.onUpdate,
          loading: false,
          error: "",
        },
      };
    case types.UPDATE_LUCKY_FAILED:
      return {
        ...state,
        onUpdate: {
          ...state.onUpdate,
          loading: false,
          error: action.message,
        },
      };

    case types.SHOW_LUCKY_LOADING:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: true,
        },
      };
    case types.SHOW_LUCKY_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          data: action.data,
          error: "",
        },
      };
    case types.SHOW_LUCKY_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          error: action.message,
        },
      };

    case types.RESET_UPLOAD_LUCKY:
      return {
        ...state,
        onCreate: {
          ...state.onCreate,
          error: null,
        },
        onUpdate: {
          ...state.onUpdate,
          error: null,
        },
      };

    case types.EMPTY_LIST_SPIN:
      return {
        ...state,
        game: {
          data: [],
          loading: false,
          error: null,
        },
      };
    default:
      return state;
  }
};
export default lucky;
