import { CARD_LIMIT } from "./data.js";
export const URL_GET_KPR = `/kprs?_limit=${CARD_LIMIT}%(params)s`;
export const URL_CREATE_KPR = `/kprs`;
export const URL_KPR = `/kprs/%(id)s`;
export const URL_GET_PICS = `/kprs/%(id)s/kpr-pics`;
export const URL_LIST_BANK_KPR = `/kprs`;

export const types = {
  GET_KPR_LOADING: "GET_KPR_LOADING",
  GET_KPR_SUCCESS: "GET_KPR_SUCCESS",
  GET_KPR_FAILED: "GET_KPR_FAILED",

  GET_LIST_BANK_KPR_LOADING: "GET_LIST_BANK_KPR_LOADING",
  GET_LIST_BANK_KPR_SUCCESS: "GET_LIST_BANK_KPR_SUCCESS",
  GET_LIST_BANK_KPR_FAILED: "GET_LIST_BANK_KPR_FAILED",

  GET_PICS_LOADING: "GET_PICS_LOADING",
  GET_PICS_SUCCESS: "GET_PICS_SUCCESS",
  GET_PICS_FAILED: "GET_PICS_FAILED",

  DELETE_KPR_LOADING: "DELETE_KPR_LOADING",
  DELETE_KPR_SUCCESS: "DELETE_KPR_SUCCESS",
  DELETE_KPR_FAILED: "DELETE_KPR_FAILED",

  CREATE_KPR_LOADING: "CREATE_KPR_LOADING",
  CREATE_KPR_SUCCESS: "CREATE_KPR_SUCCESS",
  CREATE_KPR_FAILED: "CREATE_KPR_FAILED",

  UPDATE_KPR_LOADING: "UPDATE_KPR_LOADING",
  UPDATE_KPR_SUCCESS: "UPDATE_KPR_SUCCESS",
  UPDATE_KPR_FAILED: "UPDATE_KPR_FAILED",

  SHOW_KPR_LOADING: "SHOW_KPR_LOADING",
  SHOW_KPR_SUCCESS: "SHOW_KPR_SUCCESS",
  SHOW_KPR_FAILED: "SHOW_KPR_FAILED",

  RESET_UPLOAD_KPR: "RESET_UPLOAD_KPR",
};
