import { result } from "lodash";
import { types } from "../constants/profile";

const initialState = {
  data: {},
  loading: false,
  error: null,
};

const bannerReward = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_BANNER_REWARD_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.GET_BANNER_REWARD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          data: [...action.data],
          ...action.allPageAction,
        },
        error: null,
      };
    case types.UPDATE_BANNER_REWARD_SUCCESS:
        return {
          ...state,
          loading: false,
          data: {
            data: [...result(state, 'data.data', []), ...action.data],
            ...action.allPageAction,
          },
          error: null,
        };
    case types.GET_BANNER_REWARD_FAILED:
      return {
        ...state,
        loading: false,
        error: action.message,
      };

    default:
      return state;
  }
};
export default bannerReward;
