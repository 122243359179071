import { combineReducers } from "redux";
import auth from "./auth";
import user from "./user";
import event from "./event";
import news from "./news";
import announcement from "./announcement";
import magazine from "./magazine";
import sidebar from "./sidebar";
import company from "./company";
import role from "./role";
import street from "./street";
import kind from "./kind";
import kpr from "./kpr";
import promoKpr from "./promoKpr";
import reward from "./reward";
import lucky from "./lucky";
import poin from "./poin";
import merchandise from "./merchandise";
import admin from "./admin";
import profile from "./profile";
import audiobook from "./audiobook";
import info from "./info";
import bannerRewards from './bannerRewards';
import achievementRewards from './achievementRewards';

export default combineReducers({
  auth,
  user,
  event,
  news,
  announcement,
  magazine,
  sidebar,
  lucky,
  company,
  role,
  street,
  kind,
  kpr,
  promoKpr,
  reward,
  poin,
  merchandise,
  admin,
  profile,
  audiobook,
  info,
  bannerRewards,
  achievementRewards
});
