import { CARD_LIMIT } from "./data.js";
export const URL_GET_NEWS = `/news?_limit=${CARD_LIMIT}%(params)s`;
export const URL_NEWS = "/news/%(slug)s";
export const URL_CREATE_NEWS = "/news";

export const types = {
  GET_NEWS_LOADING: "GET_NEWS_LOADING",
  GET_NEWS_SUCCESS: "GET_NEWS_SUCCESS",
  GET_NEWS_FAILED: "GET_NEWS_FAILED",

  DELETE_NEWS_LOADING: "DELETE_NEWS_LOADING",
  DELETE_NEWS_SUCCESS: "DELETE_NEWS_SUCCESS",
  DELETE_NEWS_FAILED: "DELETE_NEWS_FAILED",

  CREATE_NEWS_LOADING: "CREATE_NEWS_LOADING",
  CREATE_NEWS_SUCCESS: "CREATE_NEWS_SUCCESS",
  CREATE_NEWS_FAILED: "CREATE_NEWS_FAILED",

  UPDATE_NEWS_LOADING: "UPDATE_NEWS_LOADING",
  UPDATE_NEWS_SUCCESS: "UPDATE_NEWS_SUCCESS",
  UPDATE_NEWS_FAILED: "UPDATE_NEWS_FAILED",

  SHOW_NEWS_LOADING: "SHOW_NEWS_LOADING",
  SHOW_NEWS_SUCCESS: "SHOW_NEWS_SUCCESS",
  SHOW_NEWS_FAILED: "SHOW_NEWS_FAILED",

  RESET_UPLOAD_NEWS: "RESET_UPLOAD_NEWS",
};
