import { types } from "../constants/user";

const initialState = {
  list: {
    data: [],
    loading: false,
    error: null,
    page: 1,
    totalPage: null,
  },
  listUserCplus: {
    data: [],
    loading: false,
    error: null,
    page: 1,
    totalPage: null,
  },
  listCplus: {
    data: [],
    loading: false,
    error: null,
    page: 1,
    totalPage: null,
  },
  onDelete: {
    loading: false,
    error: null,
  },
  onDeleteCPlus: {
    loading: false,
    error: null,
  },
  onCreate: {
    loading: false,
    error: null,
  },
  onCreateCplus: {
    loading: false,
    error: null,
  },
  detailList: {
    data: {},
    loading: false,
    error: null,
  },
  detailCplusList: {
    data: {},
    loading: false,
    error: null,
  },
  onUpdate: {
    loading: false,
    error: null,
  },
  onUpdateCPlus: {
    loading: false,
    error: null,
  },
  listCareer: {
    data: [],
    loading: false,
    error: null,
    page: 1,
    totalPage: null,
  },
  onDeleteCareer: {
    loading: false,
    error: null,
  },
  onCreateCareer: {
    loading: false,
    error: null,
  },
  detailListCareer: {
    data: {},
    loading: false,
    error: null,
  },
  onUpdateCareer: {
    loading: false,
    error: null,
  },
  finds: {
    data: [],
  },
  findReward: {
    data: [],
  },
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_USER_LOADING:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          isLoadMore: true,
        },
      };
    case types.GET_USER_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: "",
          data: action.data,
          totalPage: parseInt(action.totalPage) || state.list.totalPage,
          page: action.page,
          isLoadMore: false,
        },
      };
    case types.RESET_USER:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: "",
          data: [],
          totalPage: null,
          page: 1,
          isLoadMore: false,
        },
      };
    case types.GET_USER_FAILED:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: action.message,
          isLoadMore: false,
        },
      };

    case types.GET_USER_CPLUS_FOR_CREATE_AND_EDIT_LOADING:
      return {
        ...state,
        listUserCplus: {
          ...state.listUserCplus,
          loading: true,
          isLoadMore: true,
        },
      };
    case types.GET_USER_CPLUS_FOR_CREATE_AND_EDIT_SUCCESS:
      return {
        ...state,
        listUserCplus: {
          ...state.listUserCplus,
          loading: false,
          error: "",
          data: action.data,
          totalPage: parseInt(action.totalPage) || state.listUserCplus.totalPage,
          page: action.page,
          isLoadMore: false,
        },
      };
    case types.RESET_USER:
      return {
        ...state,
        listUserCplus: {
          ...state.listUserCplus,
          loading: false,
          error: "",
          data: [],
          totalPage: null,
          page: 1,
          isLoadMore: false,
        },
      };
    case types.RESET_USER_CPLUS_FOR_CREATE_AND_EDIT:
      return {
        ...state,
        listUserCplus: {
          ...state.listUserCplus,
          loading: false,
          error: action.message,
          isLoadMore: false,
        },
      };
    case types.MORE_USER_CPLUS_FOR_CREATE_AND_EDIT_LOADING:
      return {
        ...state,
        listUserCplus: { ...state.listUserCplus, isLoadMore: true, error: null },
      };
    case types.MORE_USER_CPLUS_FOR_CREATE_AND_EDIT_SUCCESS:
      return {
        ...state,
        listUserCplus: {
          ...state.listUserCplus,
          data: [...state.listUserCplus.data, ...action.data],
          page: action.page,
          isLoadMore: false,
          error: null,
        },
      };
    case types.MORE_USER_CPLUS_FOR_CREATE_AND_EDIT_FAILED:
      return {
        ...state,
        listUserCplus: {
          ...state.listUserCplus,
          isLoadMore: false,
          error: action.message,
        },
      };

    case types.GET_USER_CPLUS_LOADING:
      return {
        ...state,
        listCplus: {
          ...state.listCplus,
          loading: true,
        },
      };
    case types.GET_USER_CPLUS_SUCCESS:
      return {
        ...state,
        listCplus: {
          ...state.listCplus,
          loading: false,
          error: "",
          data: action.data,
          totalPage: parseInt(action.totalPage) || state.listCplus.totalPage,
          page: action.page,
        },
      };
    case types.GET_USER_CPLUS_FAILED:
      return {
        ...state,
        listCplus: {
          ...state.listCplus,
          loading: false,
          error: action.message,
        },
      };
    case types.DELETE_USER_LOADING:
      return {
        ...state,
        onDelete: {
          ...state.onDelete,
          loading: true,
        },
      };
    case types.DELETE_USER_SUCCESS:
      return {
        ...state,
        onDelete: {
          ...state.onDelete,
          loading: false,
        },
      };
    case types.DELETE_USER_FAILED:
      return {
        ...state,
        onDelete: {
          ...state.onDelete,
          loading: false,
          error: action.message,
        },
      };
    case types.DELETE_USER_CPLUS_LOADING:
      return {
        ...state,
        onDeleteCPlus: {
          ...state.onDeleteCPlus,
          loading: true,
        },
      };
    case types.DELETE_USER_CPLUS_SUCCESS:
      return {
        ...state,
        onDeleteCPlus: {
          ...state.onDeleteCPlus,
          loading: false,
        },
      };
    case types.DELETE_USER_CPLUS_FAILED:
      return {
        ...state,
        onDeleteCPlus: {
          ...state.onDeleteCPlus,
          loading: false,
          error: action.message,
        },
      };
    case types.CREATE_USER_LOADING:
      return {
        ...state,
        onCreate: {
          ...state.onCreate,
          loading: true,
        },
      };
    case types.CREATE_USER_SUCCESS:
      return {
        ...state,
        onCreate: {
          ...state.onCreate,
          loading: false,
          error: null,
        },
      };
    case types.CREATE_USER_FAILED:
      return {
        ...state,
        onCreate: {
          ...state.onCreate,
          loading: false,
          error: action.message,
        },
      };
    case types.CREATE_USER_CPLUS_LOADING:
      return {
        ...state,
        onCreateCplus: {
          ...state.onCreateCplus,
          loading: true,
        },
      };
    case types.CREATE_USER_CPLUS_SUCCESS:
      return {
        ...state,
        onCreateCplus: {
          ...state.onCreateCplus,
          loading: false,
          error: null,
        },
      };
    case types.CREATE_USER_CPLUS_FAILED:
      return {
        ...state,
        onCreateCplus: {
          ...state.onCreateCplus,
          loading: false,
          error: action.message,
        },
      };

    case types.UPDATE_USER_LOADING:
      return {
        ...state,
        onUpdate: {
          ...state.onUpdate,
          loading: true,
        },
      };
    case types.UPDATE_USER_SUCCESS:
      return {
        ...state,
        onUpdate: {
          ...state.onUpdate,
          loading: false,
        },
      };
    case types.UPDATE_USER_FAILED:
      return {
        ...state,
        onUpdate: {
          ...state.onUpdate,
          loading: false,
          error: action.message,
        },
      };
    case types.UPDATE_USER_CPLUS_LOADING:
      return {
        ...state,
        onUpdateCPlus: {
          ...state.onUpdateCPlus,
          loading: true,
        },
      };
    case types.UPDATE_USER_CPLUS_SUCCESS:
      return {
        ...state,
        onUpdateCPlus: {
          ...state.onUpdateCPlus,
          loading: false,
        },
      };
    case types.UPDATE_USER_CPLUS_FAILED:
      return {
        ...state,
        onUpdateCPlus: {
          ...state.onUpdateCPlus,
          loading: false,
          error: action.message,
        },
      };
    case types.SHOW_USER_LOADING:
      return {
        ...state,
        detailList: {
          ...state.detailList,
          loading: true,
        },
      };
    case types.SHOW_USER_SUCCESS:
      return {
        ...state,
        detailList: {
          ...state.detailList,
          loading: false,
          data: action.data,
          error: null,
        },
      };
    case types.SHOW_USER_FAILED:
      return {
        ...state,
        detailList: {
          ...state.detailList,
          loading: false,
          error: action.message,
        },
      };
    case types.SHOW_USER_CPLUS_LOADING:
      return {
        ...state,
        detailCplusList: {
          ...state.detailCplusList,
          loading: true,
        },
      };
    case types.SHOW_USER_CPLUS_SUCCESS:
      return {
        ...state,
        detailCplusList: {
          ...state.detailCplusList,
          loading: false,
          data: action.data,
          error: null,
        },
      };
    case types.SHOW_USER_CPLUS_FAILED:
      return {
        ...state,
        detailCplusList: {
          ...state.detailCplusList,
          loading: false,
          error: action.message,
        },
      };
    case types.RESET_UPLOAD_ERROR:
      return {
        ...state,
        onCreate: {
          ...state.onCreate,
          error: null,
        },
        onUpdate: {
          ...state.onUpdate,
          error: null,
        },
      };

    case types.GET_CAREER_LOADING:
      return {
        ...state,
        listCareer: {
          ...state.listCareer,
          loading: true,
        },
      };
    case types.GET_CAREER_SUCCESS:
      return {
        ...state,
        listCareer: {
          ...state.listCareer,
          loading: false,
          error: "",
          data: action.data,
          totalPage: parseInt(action.totalPage) || state.listCareer.totalPage,
          page: action.page,
        },
      };
    case types.GET_CAREER_FAILED:
      return {
        ...state,
        listCareer: {
          ...state.listCareer,
          loading: false,
          error: action.message,
        },
      };
    case types.DELETE_CAREER_LOADING:
      return {
        ...state,
        onDeleteCareer: {
          ...state.onDeleteCareer,
          loading: true,
        },
      };
    case types.DELETE_CAREER_SUCCESS:
      return {
        ...state,
        onDeleteCareer: {
          ...state.onDeleteCareer,
          loading: false,
        },
      };
    case types.DELETE_CAREER_FAILED:
      return {
        ...state,
        onDeleteCareer: {
          ...state.onDeleteCareer,
          loading: false,
          error: action.message,
        },
      };
    case types.CREATE_CAREER_LOADING:
      return {
        ...state,
        onCreateCareer: {
          ...state.onCreateCareer,
          loading: true,
        },
      };
    case types.CREATE_CAREER_SUCCESS:
      return {
        ...state,
        onCreateCareer: {
          ...state.onCreateCareer,
          loading: false,
          error: null,
        },
      };
    case types.CREATE_CAREER_FAILED:
      return {
        ...state,
        onCreateCareer: {
          ...state.onCreateCareer,
          loading: false,
          error: action.message,
        },
      };

    case types.UPDATE_CAREER_LOADING:
      return {
        ...state,
        onUpdateCareer: {
          ...state.onUpdateCareer,
          loading: true,
        },
      };
    case types.UPDATE_CAREER_SUCCESS:
      return {
        ...state,
        onUpdateCareer: {
          ...state.onUpdateCareer,
          loading: false,
        },
      };
    case types.UPDATE_CAREER_FAILED:
      return {
        ...state,
        onUpdateCareer: {
          ...state.onUpdateCareer,
          loading: false,
          error: action.message,
        },
      };

    case types.SHOW_CAREER_LOADING:
      return {
        ...state,
        detailListCareer: {
          ...state.detailListCareer,
          loading: true,
        },
      };
    case types.SHOW_CAREER_SUCCESS:
      return {
        ...state,
        detailListCareer: {
          ...state.detailListCareer,
          loading: false,
          data: action.data,
          error: null,
        },
      };
    case types.SHOW_CAREER_FAILED:
      return {
        ...state,
        detailListCareer: {
          ...state.detailListCareer,
          loading: false,
          error: action.message,
        },
      };
    case types.RESET_UPLOAD_CAREER:
      return {
        ...state,
        onCreateCareer: {
          ...state.onCreateCareer,
          error: null,
        },
        onUpdateCareer: {
          ...state.onUpdateCareer,
          error: null,
        },
      };
    case types.FIND_USER:
      return {
        ...state,
        finds: {
          data: action.data,
        },
      };
    case types.FIND_USER_REWARD:
      return {
        ...state,
        findReward: {
          data: action.data,
        },
      };
    case types.MORE_OPTION_USER_LOADING:
      return {
        ...state,
        list: { ...state.list, isLoadMore: true, error: null },
      };
    case types.MORE_OPTION_USER_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          data: [...state.list.data, ...action.data],
          page: action.page,
          isLoadMore: false,
          error: null,
        },
      };
    case types.MORE_OPTION_USER_FAILED:
      return {
        ...state,
        list: {
          ...state.list,
          isLoadMore: false,
          error: action.message,
        },
      };
    default:
      return state;
  }
};
export default user;
