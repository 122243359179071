import { CARD_LIMIT } from "./data.js";
export const URL_GET_INFO = `/companies?_limit=${CARD_LIMIT}%(params)s`;
export const URL_INFO = "/companies/%(id)s";
export const URL_CREATE_INFO = "/companies";

export const types = {
  GET_INFO_LOADING: "GET_INFO_LOADING",
  GET_INFO_SUCCESS: "GET_INFO_SUCCESS",
  GET_INFO_FAILED: "GET_INFO_FAILED",

  DELETE_INFO_LOADING: "DELETE_INFO_LOADING",
  DELETE_INFO_SUCCESS: "DELETE_INFO_SUCCESS",
  DELETE_INFO_FAILED: "DELETE_INFO_FAILED",

  CREATE_INFO_LOADING: "CREATE_INFO_LOADING",
  CREATE_INFO_SUCCESS: "CREATE_INFO_SUCCESS",
  CREATE_INFO_FAILED: "CREATE_INFO_FAILED",

  UPDATE_INFO_LOADING: "UPDATE_INFO_LOADING",
  UPDATE_INFO_SUCCESS: "UPDATE_INFO_SUCCESS",
  UPDATE_INFO_FAILED: "UPDATE_INFO_FAILED",

  RESET_UPLOAD_INFO: "RESET_UPLOAD_INFO",

  SHOW_INFO_LOADING: "SHOW_INFO_LOADING",
  SHOW_INFO_SUCCESS: "SHOW_INFO_SUCCESS",
  SHOW_INFO_FAILED: "SHOW_INFO_FAILED",
};
