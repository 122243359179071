import { types } from "../constants/kpr";

const initialState = {
  list: {
    data: [],
    loading: false,
    error: null,
    page: 1,
    totalPage: null,
  },
  listBank: {
    data: [],
    loading: false,
    error: null,
    page: 1,
    totalPage: null,
  },
  onDelete: {
    loading: false,
    error: null,
  },
  onCreate: {
    loading: false,
    error: null,
  },
  onUpdate: {
    loading: false,
    error: null,
  },
  detailList: {
    data: {},
    loading: false,
    error: null,
  },
  pics: {
    data: [],
    loading: false,
    error: null,
  },
};

const kpr = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_KPR_LOADING:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
      };
    case types.GET_KPR_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: "",
          data: action.data,
          totalPage: parseInt(action.totalPage) || state.list.totalPage,
          page: action.page,
        },
      };
    case types.GET_KPR_FAILED:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: action.message,
        },
      };

    case types.GET_LIST_BANK_KPR_LOADING:
      return {
        ...state,
        listBank: {
          ...state.listBank,
          loading: true,
        },
      };
    case types.GET_LIST_BANK_KPR_SUCCESS:
      return {
        ...state,
        listBank: {
          ...state.listBank,
          loading: false,
          error: "",
          data: action.data,
          totalPage: parseInt(action.totalPage) || state.listBank.totalPage,
          page: action.page,
        },
      };
    case types.GET_LIST_BANK_KPR_FAILED:
      return {
        ...state,
        listBank: {
          ...state.listBank,
          loading: false,
          error: action.message,
        },
      };

    case types.GET_PICS_LOADING:
      return {
        ...state,
        pics: {
          ...state.pics,
          loading: true,
        },
      };
    case types.GET_PICS_SUCCESS:
      return {
        ...state,
        pics: {
          ...state.pics,
          loading: false,
          error: "",
          data: action.data,
        },
      };
    case types.GET_PICS_FAILED:
      return {
        ...state,
        pics: {
          ...state.pics,
          loading: false,
          error: action.message,
          data: [],
        },
      };
    case types.DELETE_KPR_LOADING:
      return {
        ...state,
        onDelete: {
          ...state.onDelete,
          loading: true,
        },
      };
    case types.DELETE_KPR_SUCCESS:
      return {
        ...state,
        onDelete: {
          ...state.onDelete,
          loading: false,
        },
      };
    case types.DELETE_KPR_FAILED:
      return {
        ...state,
        onDelete: {
          ...state.onDelete,
          loading: false,
          error: action.message,
        },
      };
    case types.CREATE_KPR_LOADING:
      return {
        ...state,
        onCreate: {
          ...state.onCreate,
          loading: true,
        },
      };
    case types.CREATE_KPR_SUCCESS:
      return {
        ...state,
        onCreate: {
          ...state.onCreate,
          loading: false,
          error: null,
        },
      };
    case types.CREATE_KPR_FAILED:
      return {
        ...state,
        onCreate: {
          ...state.onCreate,
          loading: false,
          error: action.message,
        },
      };

    case types.UPDATE_KPR_LOADING:
      return {
        ...state,
        onUpdate: {
          ...state.onUpdate,
          loading: true,
        },
      };
    case types.UPDATE_KPR_SUCCESS:
      return {
        ...state,
        onUpdate: {
          ...state.onUpdate,
          loading: false,
        },
      };
    case types.UPDATE_KPR_FAILED:
      return {
        ...state,
        onUpdate: {
          ...state.onUpdate,
          loading: false,
          error: action.message,
        },
      };

    case types.RESET_UPLOAD_KPR:
      return {
        ...state,
        onCreate: {
          ...state.onCreate,
          error: null,
        },
        onUpdate: {
          ...state.onUpdate,
          error: null,
        },
      };
    case types.SHOW_KPR_LOADING:
      return {
        ...state,
        detailList: {
          ...state.detailList,
          loading: true,
        },
      };
    case types.SHOW_KPR_SUCCESS:
      return {
        ...state,
        detailList: {
          ...state.detailList,
          loading: false,
          data: action.data,
          error: null,
        },
      };
    case types.SHOW_KPR_FAILED:
      return {
        ...state,
        detailList: {
          ...state.detailList,
          loading: false,
          error: action.message,
        },
      };
    default:
      return state;
  }
};
export default kpr;
