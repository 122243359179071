import React, { Component } from "react";
import { Route, Switch, Router } from "react-router-dom";
import { Provider } from "react-redux";
import { store, persistor } from "./helpers/store";
import { PersistGate } from "redux-persist/integration/react";
import "./scss/style.scss";
import history from "./history.js";
import { ToastContainer } from "react-toastify";
import * as Sentry from "@sentry/browser";
import "react-toastify/dist/ReactToastify.css";
import "moment/locale/id";

if (process.env.NODE_ENV.trim() !== "development") {
  Sentry.init({
    dsn:
      "https://6211bf5f3b1448cb84408805d180344b@o376353.ingest.sentry.io/5341552",
  });
}
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Finance = React.lazy(() => import("./views/finance"));
const EntryPage = React.lazy(() => import("./views/entry-page"));

const Activation = React.lazy(() =>
  import("./views/pages/activation/Activation")
);
const Verification = React.lazy(() =>
  import("./views/pages/activation/Verification")
);
const Success = React.lazy(() => import("./views/pages/activation/Success"));
const Lottery = React.lazy(() => import("./views/pages/others/lottery"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Game = React.lazy(() => import("./views/pages/lucky-spin/game"));
const MarketingCenter = React.lazy(() =>
  import("./views/pages/marketing-center")
);

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Router history={history}>
            <React.Suspense fallback={loading}>
              <Switch>
                <Route
                  exact
                  path="/login"
                  name="Login Page"
                  render={(props) => <Login {...props} />}
                />
                <Route
                  exact
                  path="/game/:id"
                  name="Game Page"
                  render={(props) => <Game {...props} />}
                />
                <Route
                  exact
                  path="/marketing-center/:id"
                  name="Download Marketing Center"
                  render={(props) => <MarketingCenter {...props} />}
                />
                <Route
                  exact
                  path="/activation-account/:id"
                  name="Activation Account"
                  render={(props) => <Activation {...props} />}
                />
                <Route
                  path="/activation-account/:id/verification"
                  name="Verification Account"
                  render={(props) => <Verification {...props} />}
                />
                <Route
                  path="/activation-account/:id/success"
                  name="Verified Account"
                  render={(props) => <Success {...props} />}
                />
                <Route
                  path="/lottery"
                  name="Lottery"
                  render={(props) => <Lottery {...props} />}
                />
                <Route
                  path="/admin"
                  name="Home"
                  render={(props) => <TheLayout {...props} />}
                />
                <Route
                  path="/finance"
                  name="Finance"
                  render={(props) => <Finance {...props} />}
                />
                <Route
                  path="/"
                  name="Entrance"
                  render={(props) => <EntryPage props={props} />}
                />
                <Route
                  path="*"
                  name="Page 404"
                  render={(props) => <Page404 {...props} />}
                />
              </Switch>
            </React.Suspense>
          </Router>
          <ToastContainer
            position="top-center"
            hideProgressBar={true}
            autoClose={3000}
            style={{ zIndex: 10032 }}
          />
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
