import { types } from "../constants/poin";

const initialState = {
  akumulasi: {
    data: [],
    loading: false,
    error: null,
    page: 1,
    totalPage: null,
  },
  onCreateAkumulasi: {
    loading: false,
    error: null,
  },
  listSetting: {
    data: [],
    loading: false,
    error: null,
    page: 1,
    totalPage: null,
  },
  detailSetting: {
    data: {},
    loading: false,
    error: null,
  },
  onUpdateSetting: {
    loading: false,
    error: null,
  },
  penangguhan: {
    data: [],
    loading: false,
    error: null,
    page: 1,
    totalPage: null,
  },
  onUnsuspend: {
    loading: false,
    error: null,
  },
  onSuspend: {
    loading: false,
    error: null,
  },
};

const poin = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_POIN_LOADING:
      return {
        ...state,
        akumulasi: {
          ...state.akumulasi,
          loading: true,
        },
      };
    case types.GET_POIN_SUCCESS:
      return {
        ...state,
        akumulasi: {
          ...state.akumulasi,
          loading: false,
          error: "",
          data: action.data,
          totalPage: parseInt(action.totalPage) || state.akumulasi.totalPage,
          page: action.page,
        },
      };
    case types.GET_POIN_FAILED:
      return {
        ...state,
        akumulasi: {
          ...state.akumulasi,
          loading: false,
          error: action.message,
        },
      };
    case types.GET_POIN_SETTING_LOADING:
      return {
        ...state,
        listSetting: {
          ...state.listSetting,
          loading: true,
        },
      };
    case types.GET_POIN_SETTING_SUCCESS:
      return {
        ...state,
        listSetting: {
          ...state.listSetting,
          loading: false,
          error: "",
          data: action.data,
          totalPage: parseInt(action.totalPage) || state.listSetting.totalPage,
          page: action.page,
        },
      };
    case types.GET_POIN_SETTING_FAILED:
      return {
        ...state,
        listSetting: {
          ...state.listSetting,
          loading: false,
          error: action.message,
        },
      };
    case types.SHOW_POIN_SETTING_LOADING:
      return {
        ...state,
        detailSetting: {
          ...state.detailSetting,
          loading: true,
        },
      };
    case types.SHOW_POIN_SETTING_SUCCESS:
      return {
        ...state,
        detailSetting: {
          ...state.detailSetting,
          loading: false,
          error: "",
          data: action.data,
        },
      };
    case types.SHOW_POIN_SETTING_FAILED:
      return {
        ...state,
        detailSetting: {
          ...state.detailSetting,
          loading: false,
          error: action.message,
        },
      };
    case types.UPDATE_POIN_SETTING_LOADING:
      return {
        ...state,
        onUpdateSetting: {
          ...state.onUpdateSetting,
          loading: true,
        },
      };
    case types.UPDATE_POIN_SETTING_SUCCESS:
      return {
        ...state,
        onUpdateSetting: {
          ...state.onUpdateSetting,
          loading: false,
          error: "",
        },
      };
    case types.UPDATE_POIN_SETTING_FAILED:
      return {
        ...state,
        onUpdateSetting: {
          ...state.onUpdateSetting,
          loading: false,
          error: action.message,
        },
      };
    case types.CREATE_POIN_LOADING:
      return {
        ...state,
        onCreateAkumulasi: {
          ...state.onCreateAkumulasi,
          loading: true,
        },
      };
    case types.CREATE_POIN_SUCCESS:
      return {
        ...state,
        onCreateAkumulasi: {
          ...state.onCreateAkumulasi,
          loading: false,
          error: null,
        },
      };
    case types.CREATE_POIN_FAILED:
      return {
        ...state,
        onCreateAkumulasi: {
          ...state.onCreateAkumulasi,
          loading: false,
          error: action.message,
        },
      };

    case types.RESET_UPLOAD_POIN:
      return {
        ...state,
        onCreateAkumulasi: {
          ...state.onCreateAkumulasi,
          error: null,
        },
      };

    case types.GET_SUSPEND_LOADING:
      return {
        ...state,
        penangguhan: {
          ...state.penangguhan,
          loading: true,
        },
      };
    case types.GET_SUSPEND_SUCCESS:
      return {
        ...state,
        penangguhan: {
          ...state.penangguhan,
          loading: false,
          error: "",
          data: action.data,
          totalPage: parseInt(action.totalPage) || state.penangguhan.totalPage,
          page: action.page,
        },
      };
    case types.GET_SUSPEND_FAILED:
      return {
        ...state,
        penangguhan: {
          ...state.penangguhan,
          loading: false,
          error: action.message,
        },
      };

    case types.CREATE_SUSPEND_LOADING:
      return {
        ...state,
        onSuspend: {
          ...state.onSuspend,
          loading: true,
        },
      };
    case types.CREATE_SUSPEND_SUCCESS:
      return {
        ...state,
        onSuspend: {
          ...state.onSuspend,
          loading: false,
          error: "",
        },
      };
    case types.CREATE_SUSPEND_FAILED:
      return {
        ...state,
        onSuspend: {
          ...state.onSuspend,
          loading: false,
          error: action.message,
        },
      };

    case types.UNSUSPEND_LOADING:
      return {
        ...state,
        onUnsuspend: {
          ...state.onUnsuspend,
          loading: true,
        },
      };
    case types.UNSUSPEND_SUCCESS:
      return {
        ...state,
        onUnsuspend: {
          ...state.onUnsuspend,
          loading: false,
          error: "",
        },
      };
    case types.UNSUSPEND_FAILED:
      return {
        ...state,
        onUnsuspend: {
          ...state.onUnsuspend,
          loading: false,
          error: action.message,
        },
      };
    default:
      return state;
  }
};
export default poin;
