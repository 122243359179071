import { CARD_LIMIT } from "./data.js";
export const URL_GET_EVENTS = `/events?_limit=${CARD_LIMIT}%(params)s`;
export const URL_GET_PARTICIPANTS = `/events/%(slug)s/event-bookings?_limit=20%(params)s`;
export const URL_SWITCH_PAY = `/events/%(slug)s/event-bookings/%(id)s/%(param)s`;
export const URL_EVENT = "/events/%(slug)s";
export const URL_CREATE_EVENT = "/events";
export const URL_DOORPRIZE = "/events/%(slug)s/event-lotteries/shake";
export const URL_WINNER_DOORPRIZE = "/events/%(slug)s/event-lotteries";
export const URL_PRESENCE = "/events/%(slug)s/event-presences%(param)s";

export const types = {
  GET_EVENT_LOADING: "GET_EVENT_LOADING",
  GET_EVENT_SUCCESS: "GET_EVENT_SUCCESS",
  GET_EVENT_FAILED: "GET_EVENT_FAILED",

  DELETE_EVENT_LOADING: "DELETE_EVENT_LOADING",
  DELETE_EVENT_SUCCESS: "DELETE_EVENT_SUCCESS",
  DELETE_EVENT_FAILED: "DELETE_EVENT_FAILED",

  CREATE_EVENT_LOADING: "CREATE_EVENT_LOADING",
  CREATE_EVENT_SUCCESS: "CREATE_EVENT_SUCCESS",
  CREATE_EVENT_FAILED: "CREATE_EVENT_FAILED",

  UPDATE_EVENT_LOADING: "UPDATE_EVENT_LOADING",
  UPDATE_EVENT_SUCCESS: "UPDATE_EVENT_SUCCESS",
  UPDATE_EVENT_FAILED: "UPDATE_EVENT_FAILED",

  RESET_UPLOAD_EVENT: "RESET_UPLOAD_EVENT",

  SHOW_EVENT_LOADING: "SHOW_EVENT_LOADING",
  SHOW_EVENT_SUCCESS: "SHOW_EVENT_SUCCESS",
  SHOW_EVENT_FAILED: "SHOW_EVENT_FAILED",

  DOORPRIZE_PARTICIPANT_LOADING: "DOORPRIZE_PARTICIPANT_LOADING",
  DOORPRIZE_PARTICIPANT_SUCCESS: "DOORPRIZE_PARTICIPANT_SUCCESS",
  DOORPRIZE_PARTICIPANT_FAILED: "DOORPRIZE_PARTICIPANT_FAILED",

  WINNER_DOORPRIZE_LOADING: "WINNER_DOORPRIZE_LOADING",
  WINNER_DOORPRIZE_SUCCESS: "WINNER_DOORPRIZE_SUCCESS",
  WINNER_DOORPRIZE_FAILED: "WINNER_DOORPRIZE_FAILED",

  GET_PARTICIPANT_LOADING: "GET_PARTICIPANT_LOADING",
  GET_PARTICIPANT_SUCCESS: "GET_PARTICIPANT_SUCCESS",
  GET_PARTICIPANT_FAILED: "GET_PARTICIPANT_FAILED",

  MORE_PARTICIPANT_LOADING: "MORE_PARTICIPANT_LOADING",
  MORE_PARTICIPANT_SUCCESS: "MORE_PARTICIPANT_SUCCESS",
  MORE_PARTICIPANT_FAILED: "MORE_PARTICIPANT_FAILED",
};
