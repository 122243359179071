export const URL_GET_PROFILE = "/account/profile";
export const URL_GET_BANNER_REWARDS = "/banner-awards";
export const URL_GET_ACHIEVEMENTS = `/achievements?%(params)s`;

export const types = {
  GET_PROFILE_LOADING: "GET_PROFILE_LOADING",
  GET_PROFILE_SUCCESS: "GET_PROFILE_SUCCESS",
  GET_PROFILE_FAILED: "GET_PROFILE_FAILED",

  GET_BANNER_REWARD_LOADING: "GET_BANNER_REWARD_LOADING",
  GET_BANNER_REWARD_SUCCESS: "GET_BANNER_REWARD_SUCCESS",
  UPDATE_BANNER_REWARD_SUCCESS: "UPDATE_BANNER_REWARD_SUCCESS",
  GET_BANNER_REWARD_FAILED: "GET_BANNER_REWARD_FAILED",

  GET_ACHIEVEMENT_LOADING: "GET_ACHIEVEMENT_LOADING",
  GET_ACHIEVEMENT_SUCCESS: "GET_ACHIEVEMENT_SUCCESS",
  UPDATE_ACHIEVEMENT_SUCCESS: "UPDATE_ACHIEVEMENT_SUCCESS",
  GET_ACHIEVEMENT_FAILED: "GET_ACHIEVEMENT_FAILED"
};
