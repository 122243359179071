import { types } from "../constants/auth";

const initialState = {
  auth: {
    loading: false,
    error: "",
    confirmationResult: null,
    number: "",
    success: false,
  },
};

const auth = (state = initialState.auth, action) => {
  switch (action.type) {
    case types.AUTH_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.AUTH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case types.AUTH_FAILED:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    case types.AUTH_VERIFY:
      return {
        ...state,
        confirmationResult: action.confirmationCode,
        number: action.number,
      };
    case types.AUTH_VERIFY_SUCCESS:
      return {
        ...state,
        success: true,
      };

    default:
      return state;
  }
};
export default auth;
