import { types } from "../constants/street";

const initialState = {
  province: {
    data: [],
    loading: false,
    error: null,
    page: 1,
    totalPage: null,
    isLoadMore: false,
  },
  city: {
    data: [],
    loading: false,
    error: null,
    page: 1,
    totalPage: null,
    isLoadMore: false,
  },
  area: {
    data: [],
    loading: false,
    error: null,
    page: 1,
    totalPage: null,
    isLoadMore: false,
  },
};

const street = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_OPTION_PROVINCE_LOADING:
      return {
        ...state,
        province: {
          ...state.province,
          loading: true,
        },
      };
    case types.GET_OPTION_PROVINCE_SUCCESS:
      return {
        ...state,
        province: {
          ...state.province,
          loading: false,
          error: "",
          data: action.data,
          totalPage: parseInt(action.totalPage),
          page: 1,
        },
      };
    case types.GET_OPTION_PROVINCE_FAILED:
      return {
        ...state,
        province: {
          ...state.province,
          loading: false,
          error: action.message,
        },
      };

    case types.MORE_OPTION_PROVINCE_LOADING:
      return {
        ...state,
        province: { ...state.province, isLoadMore: true, error: null },
      };
    case types.MORE_OPTION_PROVINCE_SUCCESS:
      return {
        ...state,
        province: {
          ...state.province,
          data: [...state.province.data, ...action.data],
          page: action.page,
          isLoadMore: false,
          error: null,
        },
      };
    case types.MORE_OPTION_PROVINCE_FAILED:
      return {
        ...state,
        province: {
          ...state.province,
          isLoadMore: false,
          error: action.message,
          data: [],
        },
      };

    case types.GET_OPTION_CITY_LOADING:
      return {
        ...state,
        city: {
          ...state.city,
          loading: true,
        },
      };
    case types.GET_OPTION_CITY_SUCCESS:
      return {
        ...state,
        city: {
          ...state.city,
          loading: false,
          error: "",
          data: action.data,
          totalPage: parseInt(action.totalPage),
          page: 1,
        },
      };
    case types.GET_OPTION_CITY_FAILED:
      return {
        ...state,
        city: {
          ...state.city,
          loading: false,
          error: action.message,
          data: [],
        },
      };
    case types.MORE_OPTION_CITY_LOADING:
      return {
        ...state,
        city: { ...state.city, isLoadMore: true, error: null },
      };
    case types.MORE_OPTION_CITY_SUCCESS:
      return {
        ...state,
        city: {
          ...state.city,
          data: [...state.city.data, ...action.data],
          page: action.page,
          isLoadMore: false,
          error: null,
        },
      };
    case types.MORE_OPTION_CITY_FAILED:
      return {
        ...state,
        city: {
          ...state.city,
          isLoadMore: false,
          error: action.message,
        },
      };
    case types.GET_OPTION_AREA_LOADING:
      return {
        ...state,
        area: {
          ...state.area,
          loading: true,
        },
      };
    case types.GET_OPTION_AREA_SUCCESS:
      return {
        ...state,
        area: {
          ...state.area,
          loading: false,
          error: "",
          data: action.data,
          totalPage: parseInt(action.totalPage),
          page: 1,
        },
      };
    case types.GET_OPTION_AREA_FAILED:
      return {
        ...state,
        area: {
          ...state.area,
          loading: false,
          error: action.message,
          data: [],
        },
      };

    case types.MORE_OPTION_AREA_LOADING:
      return {
        ...state,
        area: { ...state.area, isLoadMore: true, error: null },
      };
    case types.MORE_OPTION_AREA_SUCCESS:
      return {
        ...state,
        area: {
          ...state.area,
          data: [...state.area.data, ...action.data],
          page: action.page,
          isLoadMore: false,
          error: null,
        },
      };
    case types.MORE_OPTION_AREA_FAILED:
      return {
        ...state,
        area: {
          ...state.area,
          isLoadMore: false,
          error: action.message,
        },
      };
    case types.RESET_AREA:
      return {
        ...state,
        area: {
          data: [],
          loading: false,
          error: null,
          page: 1,
          totalPage: null,
          isLoadMore: false,
        },
      };
    default:
      return state;
  }
};
export default street;
