import { types } from "../constants/merchandise";

const initialState = {
  list: {
    data: [],
    loading: false,
    error: null,
    page: 1,
    totalPage: null,
  },
  listPoin: {
    data: [],
    loading: false,
    error: null,
    page: 1,
    totalPage: null,
  },
  listOrder: {
    data: [],
    loading: false,
    error: null,
    page: 1,
    totalPage: null,
  },
  detailOrder: {
    data: {},
    loading: false,
    error: null,
  },
  onDelete: {
    loading: false,
    error: null,
  },
  onCreate: {
    loading: false,
    error: null,
  },
  detailList: {
    data: {},
    loading: false,
    error: null,
  },
  onUpdate: {
    loading: false,
    error: null,
  },
};

const merchandise = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_MERCHANDISE_LOADING:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
      };
    case types.GET_MERCHANDISE_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: "",
          data: action.data,
          totalPage: parseInt(action.totalPage) || state.list.totalPage,
          page: action.page,
        },
      };
    case types.GET_MERCHANDISE_FAILED:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: action.message,
        },
      };
    case types.GET_MERCHANDISE_POIN_LOADING:
      return {
        ...state,
        listPoin: {
          ...state.listPoin,
          loading: true,
        },
      };
    case types.GET_MERCHANDISE_POIN_SUCCESS:
      return {
        ...state,
        listPoin: {
          ...state.listPoin,
          loading: false,
          error: "",
          data: action.data,
          totalPage: parseInt(action.totalPage) || state.listPoin.totalPage,
          page: action.page,
        },
      };
    case types.GET_MERCHANDISE_POIN_FAILED:
      return {
        ...state,
        listPoin: {
          ...state.listPoin,
          loading: false,
          error: action.message,
        },
      };

    case types.GET_MERCHANDISE_ORDER_LOADING:
      return {
        ...state,
        listOrder: {
          ...state.listOrder,
          loading: true,
        },
      };
    case types.GET_MERCHANDISE_ORDER_SUCCESS:
      return {
        ...state,
        listOrder: {
          ...state.listOrder,
          loading: false,
          error: "",
          data: action.data,
          totalPage: parseInt(action.totalPage) || state.listOrder.totalPage,
          page: action.page,
        },
      };
    case types.GET_MERCHANDISE_ORDER_FAILED:
      return {
        ...state,
        listOrder: {
          ...state.listOrder,
          loading: false,
          error: action.message,
        },
      };

    case types.SHOW_DETAIL_ORDER_LOADING:
      return {
        ...state,
        detailOrder: {
          ...state.detailOrder,
          loading: true,
        },
      };
    case types.SHOW_DETAIL_ORDER_SUCCESS:
      return {
        ...state,
        detailOrder: {
          ...state.detailOrder,
          loading: false,
          error: "",
          data: action.data,
        },
      };
    case types.SHOW_DETAIL_ORDER_FAILED:
      return {
        ...state,
        detailOrder: {
          ...state.detailOrder,
          loading: false,
          error: action.message,
        },
      };
    case types.DELETE_MERCHANDISE_LOADING:
      return {
        ...state,
        onDelete: {
          ...state.onDelete,
          loading: true,
        },
      };
    case types.DELETE_MERCHANDISE_SUCCESS:
      return {
        ...state,
        onDelete: {
          ...state.onDelete,
          loading: false,
          error: null,
        },
      };
    case types.DELETE_MERCHANDISE_FAILED:
      return {
        ...state,
        onDelete: {
          ...state.onDelete,
          loading: false,
          error: action.message,
        },
      };
    case types.CREATE_MERCHANDISE_LOADING:
      return {
        ...state,
        onCreate: {
          ...state.onCreate,
          loading: true,
        },
      };
    case types.CREATE_MERCHANDISE_SUCCESS:
      return {
        ...state,
        onCreate: {
          ...state.onCreate,
          loading: false,
          error: null,
        },
      };
    case types.CREATE_MERCHANDISE_FAILED:
      return {
        ...state,
        onCreate: {
          ...state.onCreate,
          loading: false,
          error: action.message,
        },
      };

    case types.UPDATE_MERCHANDISE_LOADING:
      return {
        ...state,
        onUpdate: {
          ...state.onUpdate,
          loading: true,
        },
      };
    case types.UPDATE_MERCHANDISE_SUCCESS:
      return {
        ...state,
        onUpdate: {
          ...state.onUpdate,
          loading: false,
        },
      };
    case types.UPDATE_MERCHANDISE_FAILED:
      return {
        ...state,
        onUpdate: {
          ...state.onUpdate,
          loading: false,
          error: action.message,
        },
      };

    case types.SHOW_MERCHANDISE_LOADING:
      return {
        ...state,
        detailList: {
          ...state.detailList,
          loading: true,
        },
      };
    case types.SHOW_MERCHANDISE_SUCCESS:
      return {
        ...state,
        detailList: {
          ...state.detailList,
          loading: false,
          data: action.data,
          error: null,
        },
      };
    case types.SHOW_MERCHANDISE_FAILED:
      return {
        ...state,
        detailList: {
          ...state.detailList,
          loading: false,
          error: action.message,
        },
      };

    case types.RESET_UPLOAD_MERCHANDISE:
      return {
        ...state,
        onCreate: {
          ...state.onCreate,
          error: null,
        },
        onUpdate: {
          ...state.onUpdate,
          error: null,
        },
      };
    case types.SET_SWITCH_PRODUK: {
      const dataNow = state.list.data;
      const listData = dataNow.find((obj) => obj.slug === action.slug);
      listData.is_active = !listData.is_active;
      return {
        ...state,
        list: {
          ...state.list,
          data: dataNow,
        },
      };
    }
    case types.SET_SWITCH_PRODUK_POIN: {
      const dataNow = state.listPoin.data;
      const listData = dataNow.find((obj) => obj.slug === action.slug);
      listData.is_active = !listData.is_active;
      return {
        ...state,
        listPoin: {
          ...state.listPoin,
          data: dataNow,
        },
      };
    }
    default:
      return state;
  }
};
export default merchandise;
