import { CARD_LIMIT } from "./data.js";
export const URL_GET_POIN = `/points?_limit=${CARD_LIMIT}%(params)s`;
export const URL_GET_POIN_SETTING = `/point-settings?_limit=${CARD_LIMIT}%(params)s`;
export const URL_SHOW_POIN_SETTING = `/point-settings/%(id)s`;
export const URL_CREATE_POIN = `/points`;
export const URL_GET_SUSPEND = `/user-suspends?_limit=${CARD_LIMIT}%(params)s`;
export const URL_UNSUSPEND = `/users/%(id)s/unsuspend`;
export const URL_CREATE_SUSPEND = `/user-suspends`;

export const types = {
  GET_POIN_LOADING: "GET_POIN_LOADING",
  GET_POIN_SUCCESS: "GET_POIN_SUCCESS",
  GET_POIN_FAILED: "GET_POIN_FAILED",

  GET_POIN_SETTING_LOADING: "GET_POIN_SETTING_LOADING",
  GET_POIN_SETTING_SUCCESS: "GET_POIN_SETTING_SUCCESS",
  GET_POIN_SETTING_FAILED: "GET_POIN_SETTING_FAILED",

  SHOW_POIN_SETTING_LOADING: "SHOW_POIN_SETTING_LOADING",
  SHOW_POIN_SETTING_SUCCESS: "SHOW_POIN_SETTING_SUCCESS",
  SHOW_POIN_SETTING_FAILED: "SHOW_POIN_SETTING_FAILED",

  UPDATE_POIN_SETTING_LOADING: "UPDATE_POIN_SETTING_LOADING",
  UPDATE_POIN_SETTING_SUCCESS: "UPDATE_POIN_SETTING_SUCCESS",
  UPDATE_POIN_SETTING_FAILED: "UPDATE_POIN_SETTING_FAILED",

  GET_SUSPEND_LOADING: "GET_SUSPEND_LOADING",
  GET_SUSPEND_SUCCESS: "GET_SUSPEND_SUCCESS",
  GET_SUSPEND_FAILED: "GET_SUSPEND_FAILED",

  UNSUSPEND_LOADING: "UNSUSPEND_LOADING",
  UNSUSPEND_SUCCESS: "UNSUSPEND_SUCCESS",
  UNSUSPEND_FAILED: "UNSUSPEND_FAILED",

  CREATE_POIN_LOADING: "CREATE_POIN_LOADING",
  CREATE_POIN_SUCCESS: "CREATE_POIN_SUCCESS",
  CREATE_POIN_FAILED: "CREATE_POIN_FAILED",

  CREATE_SUSPEND_LOADING: "CREATE_SUSPEND_LOADING",
  CREATE_SUSPEND_SUCCESS: "CREATE_SUSPEND_SUCCESS",
  CREATE_SUSPEND_FAILED: "CREATE_SUSPEND_FAILED",

  RESET_UPLOAD_POIN: "RESET_UPLOAD_POIN",
};
