import { types } from "../constants/company";

const initialState = {
  options: {
    data: [],
    loading: false,
    error: null,
    page: 1,
    totalPage: null,
    isLoadMore: false,
  },
};

const company = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_OPTION_COMPANY_LOADING:
      return {
        ...state,
        options: {
          ...state.options,
          loading: true,
        },
      };
    case types.GET_OPTION_COMPANY_SUCCESS:
      return {
        ...state,
        options: {
          ...state.options,
          loading: false,
          error: "",
          data: action.data,
          totalPage: parseInt(action.totalPage),
          page: 1,
        },
      };
    case types.GET_OPTION_COMPANY_FAILED:
      return {
        ...state,
        options: {
          ...state.options,
          loading: false,
          error: action.message,
        },
      };
    case types.MORE_OPTION_COMPANY_LOADING:
      return {
        ...state,
        options: { ...state.options, isLoadMore: true, error: null },
      };
    case types.MORE_OPTION_COMPANY_SUCCESS:
      return {
        ...state,
        options: {
          ...state.options,
          data: [...state.options.data, ...action.data],
          page: action.page,
          isLoadMore: false,
          error: null,
        },
      };
    case types.MORE_OPTION_COMPANY_FAILED:
      return {
        ...state,
        options: {
          ...state.options,
          isLoadMore: false,
          error: action.message,
        },
      };
    default:
      return state;
  }
};
export default company;
