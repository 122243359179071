import { types } from "../constants/promoKpr";

const initialState = {
  list: {
    data: [],
    loading: false,
    error: null,
    page: 1,
    totalPage: null,
  },
  onDelete: {
    loading: false,
    error: null,
  },
  onCreate: {
    loading: false,
    error: null,
  },
  onUpdate: {
    loading: false,
    error: null,
  },
  detailList: {
    data: {},
    loading: false,
    error: null,
  },
};

const promoKpr = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PROMO_KPR_LOADING:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
      };
    case types.GET_PROMO_KPR_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: "",
          data: action.data,
          totalPage: parseInt(action.totalPage) || state.list.totalPage,
          page: action.page,
        },
      };
    case types.GET_PROMO_KPR_FAILED:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: action.message,
        },
      };
    case types.DELETE_PROMO_KPR_LOADING:
      return {
        ...state,
        onDelete: {
          ...state.onDelete,
          loading: true,
        },
      };
    case types.DELETE_PROMO_KPR_SUCCESS:
      return {
        ...state,
        onDelete: {
          ...state.onDelete,
          loading: false,
        },
      };
    case types.DELETE_PROMO_KPR_FAILED:
      return {
        ...state,
        onDelete: {
          ...state.onDelete,
          loading: false,
          error: action.message,
        },
      };
    case types.CREATE_PROMO_KPR_LOADING:
      return {
        ...state,
        onCreate: {
          ...state.onCreate,
          loading: true,
        },
      };
    case types.CREATE_PROMO_KPR_SUCCESS:
      return {
        ...state,
        onCreate: {
          ...state.onCreate,
          loading: false,
          error: null,
        },
      };
    case types.CREATE_PROMO_KPR_FAILED:
      return {
        ...state,
        onCreate: {
          ...state.onCreate,
          loading: false,
          error: action.message,
        },
      };

    case types.UPDATE_PROMO_KPR_LOADING:
      return {
        ...state,
        onUpdate: {
          ...state.onUpdate,
          loading: true,
        },
      };
    case types.UPDATE_PROMO_KPR_SUCCESS:
      return {
        ...state,
        onUpdate: {
          ...state.onUpdate,
          loading: false,
        },
      };
    case types.UPDATE_PROMO_KPR_FAILED:
      return {
        ...state,
        onUpdate: {
          ...state.onUpdate,
          loading: false,
          error: action.message,
        },
      };

    case types.RESET_UPLOAD_KPR:
      return {
        ...state,
        onCreate: {
          ...state.onCreate,
          error: null,
        },
        onUpdate: {
          ...state.onUpdate,
          error: null,
        },
      };
    case types.SHOW_PROMO_KPR_LOADING:
      return {
        ...state,
        detailList: {
          ...state.detailList,
          loading: true,
        },
      };
    case types.SHOW_PROMO_KPR_SUCCESS:
      return {
        ...state,
        detailList: {
          ...state.detailList,
          loading: false,
          data: action.data,
          error: null,
        },
      };
    case types.SHOW_PROMO_KPR_FAILED:
      return {
        ...state,
        detailList: {
          ...state.detailList,
          loading: false,
          error: action.message,
        },
      };
    default:
      return state;
  }
};
export default promoKpr;
