import { types } from "../constants/kind";

const initialState = {
  list: {
    data: "",
    loading: false,
    error: null,
  },
};

const kind = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_KIND_LOADING:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
      };
    case types.GET_KIND_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: "",
          data: action.data,
        },
      };
    case types.GET_KIND_FAILED:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: action.message,
        },
      };

    default:
      return state;
  }
};
export default kind;
