import { CARD_LIMIT } from "./data.js";
export const URL_GET_ANNOUNCEMENT = `/slides?_limit=${CARD_LIMIT}%(params)s`;
export const URL_ANNOUNCEMENT = "/slides/%(slug)s";
export const URL_CREATE_ANNOUNCEMENT = "/slides";

export const types = {
  GET_ANNOUNCEMENT_LOADING: "GET_ANNOUNCEMENT_LOADING",
  GET_ANNOUNCEMENT_SUCCESS: "GET_ANNOUNCEMENT_SUCCESS",
  GET_ANNOUNCEMENT_FAILED: "GET_ANNOUNCEMENT_FAILED",

  DELETE_ANNOUNCEMENT_LOADING: "DELETE_ANNOUNCEMENT_LOADING",
  DELETE_ANNOUNCEMENT_SUCCESS: "DELETE_ANNOUNCEMENT_SUCCESS",
  DELETE_ANNOUNCEMENT_FAILED: "DELETE_ANNOUNCEMENT_FAILED",

  CREATE_ANNOUNCEMENT_LOADING: "CREATE_ANNOUNCEMENT_LOADING",
  CREATE_ANNOUNCEMENT_SUCCESS: "CREATE_ANNOUNCEMENT_SUCCESS",
  CREATE_ANNOUNCEMENT_FAILED: "CREATE_ANNOUNCEMENT_FAILED",

  UPDATE_ANNOUNCEMENT_LOADING: "UPDATE_ANNOUNCEMENT_LOADING",
  UPDATE_ANNOUNCEMENT_SUCCESS: "UPDATE_ANNOUNCEMENT_SUCCESS",
  UPDATE_ANNOUNCEMENT_FAILED: "UPDATE_ANNOUNCEMENT_FAILED",

  SHOW_ANNOUNCEMENT_LOADING: "SHOW_ANNOUNCEMENT_LOADING",
  SHOW_ANNOUNCEMENT_SUCCESS: "SHOW_ANNOUNCEMENT_SUCCESS",
  SHOW_ANNOUNCEMENT_FAILED: "SHOW_ANNOUNCEMENT_FAILED",

  RESET_UPLOAD_ANNOUNCEMENT: "RESET_UPLOAD_ANNOUNCEMENT",
};
