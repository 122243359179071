import { CARD_LIMIT } from "./data.js";

const URL_PROMO_KPR = "kpr-promos";
export const URL_MAIN_PROMO_KPR = `/${URL_PROMO_KPR}`;
export const URL_GET_PROMO_KPR = `/${URL_PROMO_KPR}?_limit=${CARD_LIMIT}%(params)s`;
export const URL_PROMO_KPR_WITH_ID = `/${URL_PROMO_KPR}/%(id)s`;

export const types = {
  GET_PROMO_KPR_LOADING: "GET_PROMO_KPR_LOADING",
  GET_PROMO_KPR_SUCCESS: "GET_PROMO_KPR_SUCCESS",
  GET_PROMO_KPR_FAILED: "GET_PROMO_KPR_FAILED",

  DELETE_PROMO_KPR_LOADING: "DELETE_PROMO_KPR_LOADING",
  DELETE_PROMO_KPR_SUCCESS: "DELETE_PROMO_KPR_SUCCESS",
  DELETE_PROMO_KPR_FAILED: "DELETE_PROMO_KPR_FAILED",

  CREATE_PROMO_KPR_LOADING: "CREATE_PROMO_KPR_LOADING",
  CREATE_PROMO_KPR_SUCCESS: "CREATE_PROMO_KPR_SUCCESS",
  CREATE_PROMO_KPR_FAILED: "CREATE_PROMO_KPR_FAILED",

  UPDATE_PROMO_KPR_LOADING: "UPDATE_PROMO_KPR_LOADING",
  UPDATE_PROMO_KPR_SUCCESS: "UPDATE_PROMO_KPR_SUCCESS",
  UPDATE_PROMO_KPR_FAILED: "UPDATE_PROMO_KPR_FAILED",

  SHOW_PROMO_KPR_LOADING: "SHOW_PROMO_KPR_LOADING",
  SHOW_PROMO_KPR_SUCCESS: "SHOW_PROMO_KPR_SUCCESS",
  SHOW_PROMO_KPR_FAILED: "SHOW_PROMO_KPR_FAILED",

  RESET_UPLOAD_PROMO_KPR: "RESET_UPLOAD_PROMO_KPR",
};
