import { CARD_LIMIT } from "./data.js";
export const URL_GET_MAGAZINE = `/magazines?_limit=${CARD_LIMIT}%(params)s`;
export const URL_MAGAZINE = "/magazines/%(id)s";
export const URL_CREATE_MAGAZINE = "/magazines";

export const types = {
  GET_MAGAZINE_LOADING: "GET_MAGAZINE_LOADING",
  GET_MAGAZINE_SUCCESS: "GET_MAGAZINE_SUCCESS",
  GET_MAGAZINE_FAILED: "GET_MAGAZINE_FAILED",

  DELETE_MAGAZINE_LOADING: "DELETE_MAGAZINE_LOADING",
  DELETE_MAGAZINE_SUCCESS: "DELETE_MAGAZINE_SUCCESS",
  DELETE_MAGAZINE_FAILED: "DELETE_MAGAZINE_FAILED",

  CREATE_MAGAZINE_LOADING: "CREATE_MAGAZINE_LOADING",
  CREATE_MAGAZINE_SUCCESS: "CREATE_MAGAZINE_SUCCESS",
  CREATE_MAGAZINE_FAILED: "CREATE_MAGAZINE_FAILED",

  UPDATE_MAGAZINE_LOADING: "UPDATE_MAGAZINE_LOADING",
  UPDATE_MAGAZINE_SUCCESS: "UPDATE_MAGAZINE_SUCCESS",
  UPDATE_MAGAZINE_FAILED: "UPDATE_MAGAZINE_FAILED",

  SHOW_MAGAZINE_LOADING: "SHOW_MAGAZINE_LOADING",
  SHOW_MAGAZINE_SUCCESS: "SHOW_MAGAZINE_SUCCESS",
  SHOW_MAGAZINE_FAILED: "SHOW_MAGAZINE_FAILED",

  RESET_UPLOAD_MAGAZINE: "RESET_UPLOAD_MAGAZINE",
};
