export const URL_ROLE = `/roles?%(params)s`;

export const types = {
  GET_OPTION_ROLE_LOADING: "GET_OPTION_ROLE_LOADING",
  GET_OPTION_ROLE_SUCCESS: "GET_OPTION_ROLE_SUCCESS",
  GET_OPTION_ROLE_FAILED: "GET_OPTION_ROLE_FAILED",

  MORE_OPTION_ROLE_LOADING: "MORE_OPTION_ROLE_LOADING",
  MORE_OPTION_ROLE_SUCCESS: "MORE_OPTION_ROLE_SUCCESS",
  MORE_OPTION_ROLE_FAILED: "MORE_OPTION_ROLE_FAILED",
};
