export const URL_LOGIN = "/account/sign-in";
export const URL_LOGOUT = "/account/sign-out";
export const URL_VALIDATE_PHONE = "/validate/users/phone-user-recovery";
export const URL_USER_RECOVERY = "/user-recoveries/%(token)s?kind=phone";
export const types = {
  AUTH_LOADING: "AUTH_LOADING",
  AUTH_SUCCESS: "AUTH_SUCCESS",
  AUTH_FAILED: "AUTH_FAILED",
  AUTH_VERIFY: "AUTH_VERIFY",
  AUTH_VERIFY_SUCCESS: "AUTH_VERIFY_SUCCESS",

  VALIDATE_LOADING: "VALIDATE_LOADING",
  VALIDATE_SUCCESS: "VALIDATE_SUCCESS",
  VALIDATE_FAILED: "VALIDATE_FAILED",
};
