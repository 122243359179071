import { CARD_LIMIT } from "./data.js";
export const URL_GET_AUDIOBOOK_PLAYLIST = `/audio-playlists?_limit=${CARD_LIMIT}%(params)s`;
export const URL_AUDIOBOOK_PLAYLIST = "/audio-playlists/%(id)s";
export const URL_AUDIOBOOK_PLAYLIST_PLAYED =
  "/audio-playlists/%(id)s/played-users";
export const URL_CREATE_AUDIOBOOK_PLAYLIST = "/audio-playlists";
export const URL_GET_BANNER = `/audio-banners?_limit=${CARD_LIMIT}%(params)s`;
export const URL_BANNER = "/audio-banners/%(id)s";
export const URL_BANNER_UPDATE = "/audio-banners";

export const URL_GET_AUDIOBOOK = `/audio-playlists/%(id)s/audio-books?_limit=${CARD_LIMIT}%(params)s`;
export const URL_AUDIOBOOK = "/audio-books/%(id)s";
export const URL_AUDIOBOOK_PLAYED = "/audio-books/%(id)s/played-users";
export const URL_CREATE_AUDIOBOOK = "/audio-books";

export const types = {
  GET_AUDIOBOOK_PLAYLIST_LOADING: "GET_AUDIOBOOK_PLAYLIST_LOADING",
  GET_AUDIOBOOK_PLAYLIST_SUCCESS: "GET_AUDIOBOOK_PLAYLIST_SUCCESS",
  GET_AUDIOBOOK_PLAYLIST_FAILED: "GET_AUDIOBOOK_PLAYLIST_FAILED",

  SHOW_AUDIOBOOK_PLAYLIST_PLAYED_LOADING:
    "SHOW_AUDIOBOOK_PLAYLIST_PLAYED_LOADING",
  SHOW_AUDIOBOOK_PLAYLIST_PLAYED_SUCCESS:
    "SHOW_AUDIOBOOK_PLAYLIST_PLAYED_SUCCESS",
  SHOW_AUDIOBOOK_PLAYLIST_PLAYED_FAILED:
    "SHOW_AUDIOBOOK_PLAYLIST_PLAYED_FAILED",

  DELETE_AUDIOBOOK_PLAYLIST_LOADING: "DELETE_AUDIOBOOK_PLAYLIST_LOADING",
  DELETE_AUDIOBOOK_PLAYLIST_SUCCESS: "DELETE_AUDIOBOOK_PLAYLIST_SUCCESS",
  DELETE_AUDIOBOOK_PLAYLIST_FAILED: "DELETE_AUDIOBOOK_PLAYLIST_FAILED",

  CREATE_AUDIOBOOK_PLAYLIST_LOADING: "CREATE_AUDIOBOOK_PLAYLIST_LOADING",
  CREATE_AUDIOBOOK_PLAYLIST_SUCCESS: "CREATE_AUDIOBOOK_PLAYLIST_SUCCESS",
  CREATE_AUDIOBOOK_PLAYLIST_FAILED: "CREATE_AUDIOBOOK_PLAYLIST_FAILED",

  UPDATE_AUDIOBOOK_PLAYLIST_LOADING: "UPDATE_AUDIOBOOK_PLAYLIST_LOADING",
  UPDATE_AUDIOBOOK_PLAYLIST_SUCCESS: "UPDATE_AUDIOBOOK_PLAYLIST_SUCCESS",
  UPDATE_AUDIOBOOK_PLAYLIST_FAILED: "UPDATE_AUDIOBOOK_PLAYLIST_FAILED",

  SHOW_AUDIOBOOK_PLAYLIST_LOADING: "SHOW_AUDIOBOOK_PLAYLIST_LOADING",
  SHOW_AUDIOBOOK_PLAYLIST_SUCCESS: "SHOW_AUDIOBOOK_PLAYLIST_SUCCESS",
  SHOW_AUDIOBOOK_PLAYLIST_FAILED: "SHOW_AUDIOBOOK_PLAYLIST_FAILED",

  GET_AUDIOBOOK_LOADING: "GET_AUDIOBOOK_LOADING",
  GET_AUDIOBOOK_SUCCESS: "GET_AUDIOBOOK_SUCCESS",
  GET_AUDIOBOOK_FAILED: "GET_AUDIOBOOK_FAILED",

  SHOW_AUDIOBOOK_PLAYED_LOADING: "SHOW_AUDIOBOOK_PLAYED_LOADING",
  SHOW_AUDIOBOOK_PLAYED_SUCCESS: "SHOW_AUDIOBOOK_PLAYED_SUCCESS",
  SHOW_AUDIOBOOK_PLAYED_FAILED: "SHOW_AUDIOBOOK_PLAYED_FAILED",

  DELETE_AUDIOBOOK_LOADING: "DELETE_AUDIOBOOK_LOADING",
  DELETE_AUDIOBOOK_SUCCESS: "DELETE_AUDIOBOOK_SUCCESS",
  DELETE_AUDIOBOOK_FAILED: "DELETE_AUDIOBOOK_FAILED",

  GET_BANNER_LOADING: "GET_BANNER_LOADING",
  GET_BANNER_SUCCESS: "GET_BANNER_SUCCESS",
  GET_BANNER_FAILED: "GET_BANNER_FAILED",

  UPDATE_BANNER_LOADING: "UPDATE_BANNER_LOADING",
  UPDATE_BANNER_SUCCESS: "UPDATE_BANNER_SUCCESS",
  UPDATE_BANNER_FAILED: "UPDATE_BANNER_FAILED",

  DELETE_BANNER_LOADING: "DELETE_BANNER_LOADING",
  DELETE_BANNER_SUCCESS: "DELETE_BANNER_SUCCESS",
  DELETE_BANNER_FAILED: "DELETE_BANNER_FAILED",

  CREATE_AUDIOBOOK_LOADING: "CREATE_AUDIOBOOK_LOADING",
  CREATE_AUDIOBOOK_SUCCESS: "CREATE_AUDIOBOOK_SUCCESS",
  CREATE_AUDIOBOOK_FAILED: "CREATE_AUDIOBOOK_FAILED",

  UPDATE_AUDIOBOOK_LOADING: "UPDATE_AUDIOBOOK_LOADING",
  UPDATE_AUDIOBOOK_SUCCESS: "UPDATE_AUDIOBOOK_SUCCESS",
  UPDATE_AUDIOBOOK_FAILED: "UPDATE_AUDIOBOOK_FAILED",

  SHOW_AUDIOBOOK_LOADING: "SHOW_AUDIOBOOK_LOADING",
  SHOW_AUDIOBOOK_SUCCESS: "SHOW_AUDIOBOOK_SUCCESS",
  SHOW_AUDIOBOOK_FAILED: "SHOW_AUDIOBOOK_FAILED",

  RESET_UPLOAD_AUDIOBOOK: "RESET_UPLOAD_AUDIOBOOK",
  RESET_UPLOAD_AUDIOBOOK_PLAYLIST: "RESET_UPLOAD_AUDIOBOOK_PLAYLIST",
  SET_ORDER_BANNER: "SET_ORDER_BANNER",
  SET_ACTIVE_BANNER: "SET_ACTIVE_BANNER",
};
