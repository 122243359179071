import { CARD_LIMIT } from "./data.js";
export const URL_GET_MERCHANDISE = `/merchandise-products?redeem_money=true&redeem_point=true&_limit=${CARD_LIMIT}%(params)s`;
export const URL_GET_MERCHANDISE_POIN = `/merchandise-products?redeem_money=false&_limit=${CARD_LIMIT}%(params)s`;
export const URL_GET_MERCHANDISE_ORDER = `/merchandise-orders?_limit=${CARD_LIMIT}%(params)s`;
export const URL_SHOW_DETAIL_ORDER = `/merchandise-orders/%(id)s`;
export const URL_SWITCH_TO_DELIVERY_PROCESS = `/merchandise-orders/%(id)s/delivery-process`;
export const URL_CREATE_MERCHANDISE = `/merchandise-products`;
export const URL_MERCHANDISE = `/merchandise-products/%(slug)s`;
export const URL_SWITCH_ACTIVE_PRODUK = `${URL_MERCHANDISE}/is-active`;

export const types = {
  GET_MERCHANDISE_LOADING: "GET_MERCHANDISE_LOADING",
  GET_MERCHANDISE_SUCCESS: "GET_MERCHANDISE_SUCCESS",
  GET_MERCHANDISE_FAILED: "GET_MERCHANDISE_FAILED",

  SHOW_DETAIL_ORDER_LOADING: "SHOW_DETAIL_ORDER_LOADING",
  SHOW_DETAIL_ORDER_SUCCESS: "SHOW_DETAIL_ORDER_SUCCESS",
  SHOW_DETAIL_ORDER_FAILED: "SHOW_DETAIL_ORDER_FAILED",

  GET_MERCHANDISE_ORDER_LOADING: "GET_MERCHANDISE_ORDER_LOADING",
  GET_MERCHANDISE_ORDER_SUCCESS: "GET_MERCHANDISE_ORDER_SUCCESS",
  GET_MERCHANDISE_ORDER_FAILED: "GET_MERCHANDISE_ORDER_FAILED",

  GET_MERCHANDISE_POIN_LOADING: "GET_MERCHANDISE_POIN_LOADING",
  GET_MERCHANDISE_POIN_SUCCESS: "GET_MERCHANDISE_POIN_SUCCESS",
  GET_MERCHANDISE_POIN_FAILED: "GET_MERCHANDISE_POIN_FAILED",

  DELETE_MERCHANDISE_LOADING: "DELETE_MERCHANDISE_LOADING",
  DELETE_MERCHANDISE_SUCCESS: "DELETE_MERCHANDISE_SUCCESS",
  DELETE_MERCHANDISE_FAILED: "DELETE_MERCHANDISE_FAILED",

  CREATE_MERCHANDISE_LOADING: "CREATE_MERCHANDISE_LOADING",
  CREATE_MERCHANDISE_SUCCESS: "CREATE_MERCHANDISE_SUCCESS",
  CREATE_MERCHANDISE_FAILED: "CREATE_MERCHANDISE_FAILED",

  UPDATE_MERCHANDISE_LOADING: "UPDATE_MERCHANDISE_LOADING",
  UPDATE_MERCHANDISE_SUCCESS: "UPDATE_MERCHANDISE_SUCCESS",
  UPDATE_MERCHANDISE_FAILED: "UPDATE_MERCHANDISE_FAILED",

  SHOW_MERCHANDISE_LOADING: "SHOW_MERCHANDISE_LOADING",
  SHOW_MERCHANDISE_SUCCESS: "SHOW_MERCHANDISE_SUCCESS",
  SHOW_MERCHANDISE_FAILED: "SHOW_MERCHANDISE_FAILED",

  RESET_UPLOAD_MERCHANDISE: "RESET_UPLOAD_MERCHANDISE",

  SET_SWITCH_PRODUK: "SET_SWITCH_PRODUK",
  SET_SWITCH_PRODUK_POIN: "SET_SWITCH_PRODUK_POIN",
};
