import { types } from "../constants/audiobook";

const initialState = {
  listPlaylist: {
    data: [],
    loading: false,
    error: null,
    page: 1,
    totalPage: null,
  },
  listAudiobook: {
    data: [],
    loading: false,
    error: null,
    page: 1,
    totalPage: null,
  },
  listBanner: {
    data: [],
    loading: false,
    error: null,
    page: 1,
    totalPage: null,
  },
  onDelete: {
    loading: false,
    error: null,
  },
  onCreate: {
    loading: false,
    error: null,
  },
  detailList: {
    data: [],
    loading: false,
    error: null,
    page: 1,
    totalPage: null,
  },
  detailListPlayed: {
    data: [],
    loading: false,
    error: null,
    page: 1,
    totalPage: null,
  },
  detailPlaylist: {
    data: [],
    loading: false,
    error: null,
    page: 1,
    totalPage: null,
  },
  detailPlaylistPlayed: {
    data: [],
    loading: false,
    error: null,
    page: 1,
    totalPage: null,
  },
  onUpdate: {
    loading: false,
    error: null,
  },
};

const orderItem = (state, data) => {
  const index = data?.type == "up" ? data?.index - 1 : data?.index + 1;
  const moveArray = ([state[data.index], state[index]] = [
    state[index],
    state[data.index],
  ]);
  return state;
};

const activeItem = (state, action) => {
  state.map((data, index) => {
    if (index == action.index) {
      if (data.announcement == true) {
        data.announcement = false;
      } else {
        data.announcement = true;
      }
    }
  });
  return state;
};
const audiobook = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_AUDIOBOOK_PLAYLIST_LOADING:
      return {
        ...state,
        listPlaylist: {
          ...state.listPlaylist,
          loading: true,
        },
      };
    case types.GET_AUDIOBOOK_PLAYLIST_SUCCESS:
      return {
        ...state,
        listPlaylist: {
          ...state.listPlaylist,
          loading: false,
          error: "",
          data: action.data,
          totalPage: parseInt(action.totalPage) || state.listPlaylist.totalPage,
          page: action.page,
        },
      };
    case types.GET_AUDIOBOOK_PLAYLIST_FAILED:
      return {
        ...state,
        listPlaylist: {
          ...state.listPlaylist,
          loading: false,
          error: action.message,
        },
      };

    case types.GET_AUDIOBOOK_LOADING:
      return {
        ...state,
        listAudiobook: {
          ...state.listAudiobook,
          loading: true,
        },
      };
    case types.GET_AUDIOBOOK_SUCCESS:
      return {
        ...state,
        listAudiobook: {
          ...state.listAudiobook,
          loading: false,
          error: "",
          data: action.data,
          totalPage: parseInt(action.totalPage) || state.list.totalPage,
          page: action.page,
        },
      };
    case types.GET_AUDIOBOOK_FAILED:
      return {
        ...state,
        listAudiobook: {
          ...state.listAudiobook,
          loading: false,
          error: action.message,
        },
      };
    case types.DELETE_AUDIOBOOK_PLAYLIST_LOADING:
      return {
        ...state,
        onDelete: {
          ...state.onDelete,
          loading: true,
        },
      };
    case types.DELETE_AUDIOBOOK_PLAYLIST_SUCCESS:
      return {
        ...state,
        onDelete: {
          ...state.onDelete,
          loading: false,
          error: null,
        },
      };
    case types.DELETE_AUDIOBOOK_PLAYLIST_FAILED:
      return {
        ...state,
        onDelete: {
          ...state.onDelete,
          loading: false,
          error: action.message,
        },
      };
    case types.DELETE_AUDIOBOOK_LOADING:
      return {
        ...state,
        onDelete: {
          ...state.onDelete,
          loading: true,
        },
      };
    case types.DELETE_AUDIOBOOK_SUCCESS:
      return {
        ...state,
        onDelete: {
          ...state.onDelete,
          loading: false,
          error: null,
        },
      };
    case types.DELETE_AUDIOBOOK_FAILED:
      return {
        ...state,
        onDelete: {
          ...state.onDelete,
          loading: false,
          error: action.message,
        },
      };
    case types.CREATE_AUDIOBOOK_PLAYLIST_LOADING:
      return {
        ...state,
        onCreate: {
          ...state.onCreate,
          loading: true,
        },
      };
    case types.CREATE_AUDIOBOOK_PLAYLIST_SUCCESS:
      return {
        ...state,
        onCreate: {
          ...state.onCreate,
          loading: false,
          error: null,
        },
      };
    case types.CREATE_AUDIOBOOK_PLAYLIST_FAILED:
      return {
        ...state,
        onCreate: {
          ...state.onCreate,
          loading: false,
          error: action.message,
        },
      };

    case types.CREATE_AUDIOBOOK_LOADING:
      return {
        ...state,
        onCreate: {
          ...state.onCreate,
          loading: true,
        },
      };
    case types.CREATE_AUDIOBOOK_SUCCESS:
      return {
        ...state,
        onCreate: {
          ...state.onCreate,
          loading: false,
          error: null,
        },
      };
    case types.CREATE_AUDIOBOOK_FAILED:
      return {
        ...state,
        onCreate: {
          ...state.onCreate,
          loading: false,
          error: action.message,
        },
      };

    case types.UPDATE_AUDIOBOOK_PLAYLIST_LOADING:
      return {
        ...state,
        onUpdate: {
          ...state.onUpdate,
          loading: true,
        },
      };
    case types.UPDATE_AUDIOBOOK_PLAYLIST_SUCCESS:
      return {
        ...state,
        onUpdate: {
          ...state.onUpdate,
          loading: false,
        },
      };
    case types.UPDATE_AUDIOBOOK_PLAYLIST_FAILED:
      return {
        ...state,
        onUpdate: {
          ...state.onUpdate,
          loading: false,
          error: action.message,
        },
      };

    case types.UPDATE_AUDIOBOOK_LOADING:
      return {
        ...state,
        onUpdate: {
          ...state.onUpdate,
          loading: true,
        },
      };
    case types.UPDATE_AUDIOBOOK_SUCCESS:
      return {
        ...state,
        onUpdate: {
          ...state.onUpdate,
          loading: false,
        },
      };
    case types.UPDATE_AUDIOBOOK_FAILED:
      return {
        ...state,
        onUpdate: {
          ...state.onUpdate,
          loading: false,
          error: action.message,
        },
      };

    case types.SHOW_AUDIOBOOK_PLAYLIST_LOADING:
      return {
        ...state,
        detailPlaylist: {
          ...state.detailPlaylist,
          loading: true,
        },
      };
    case types.SHOW_AUDIOBOOK_PLAYLIST_SUCCESS:
      return {
        ...state,
        detailPlaylist: {
          ...state.detailPlaylist,
          loading: false,
          data: action.data,
          error: null,
        },
      };
    case types.SHOW_AUDIOBOOK_PLAYLIST_FAILED:
      return {
        ...state,
        detailPlaylist: {
          ...state.detailPlaylist,
          loading: false,
          error: action.message,
        },
      };

    case types.SHOW_AUDIOBOOK_PLAYLIST_PLAYED_LOADING:
      return {
        ...state,
        detailPlaylistPlayed: {
          ...state.detailPlaylistPlayed,
          loading: true,
        },
      };
    case types.SHOW_AUDIOBOOK_PLAYLIST_PLAYED_SUCCESS:
      return {
        ...state,
        detailPlaylistPlayed: {
          ...state.detailPlaylistPlayed,
          loading: false,
          data: action.data,
          error: null,
        },
      };
    case types.SHOW_AUDIOBOOK_PLAYLIST_PLAYED_FAILED:
      return {
        ...state,
        detailPlaylistPlayed: {
          ...state.detailPlaylistPlayed,
          loading: false,
          error: action.message,
        },
      };

    case types.SHOW_AUDIOBOOK_LOADING:
      return {
        ...state,
        detailList: {
          ...state.detailList,
          loading: true,
        },
      };
    case types.SHOW_AUDIOBOOK_SUCCESS:
      return {
        ...state,
        detailList: {
          ...state.detailList,
          loading: false,
          data: action.data,
          error: null,
        },
      };
    case types.SHOW_AUDIOBOOK_FAILED:
      return {
        ...state,
        detailList: {
          ...state.detailList,
          loading: false,
          error: action.message,
        },
      };

    case types.SHOW_AUDIOBOOK_PLAYED_LOADING:
      return {
        ...state,
        detailListPlayed: {
          ...state.detailListPlayed,
          loading: true,
        },
      };
    case types.SHOW_AUDIOBOOK_PLAYED_SUCCESS:
      return {
        ...state,
        detailListPlayed: {
          ...state.detailListPlayed,
          loading: false,
          data: action.data,
          error: null,
        },
      };
    case types.SHOW_AUDIOBOOK_PLAYED_FAILED:
      return {
        ...state,
        detailListPlayed: {
          ...state.detailListPlayed,
          loading: false,
          error: action.message,
        },
      };

    case types.RESET_UPLOAD_AUDIOBOOK_PLAYLIST:
      return {
        ...state,
        onCreate: {
          ...state.onCreate,
          error: null,
        },
        onUpdate: {
          ...state.onUpdate,
          error: null,
        },
      };

    case types.RESET_UPLOAD_AUDIOBOOK:
      return {
        ...state,
        onCreate: {
          ...state.onCreate,
          error: null,
        },
        onUpdate: {
          ...state.onUpdate,
          error: null,
        },
      };

    case types.GET_BANNER_LOADING:
      return {
        ...state,
        listBanner: {
          ...state.listBanner,
          loading: true,
        },
      };
    case types.GET_BANNER_SUCCESS:
      return {
        ...state,
        listBanner: {
          ...state.listBanner,
          loading: false,
          error: "",
          data: action.data,
          totalPage: parseInt(action.totalPage) || state.listBanner.totalPage,
          page: action.page,
        },
      };
    case types.GET_BANNER_FAILED:
      return {
        ...state,
        listBanner: {
          ...state.listBanner,
          loading: false,
          error: action.message,
        },
      };
    case types.DELETE_BANNER_LOADING:
      return {
        ...state,
        onDelete: {
          ...state.onDelete,
          loading: true,
        },
      };
    case types.DELETE_BANNER_SUCCESS:
      return {
        ...state,
        onDelete: {
          ...state.onDelete,
          loading: false,
          error: null,
        },
      };
    case types.DELETE_BANNER_FAILED:
      return {
        ...state,
        onDelete: {
          ...state.onDelete,
          loading: false,
          error: action.message,
        },
      };
    case types.SET_ORDER_BANNER:
      return {
        ...state,
        listBanner: {
          ...state.listBanner,
          loading: false,
          error: "",
          data: orderItem(state.listBanner.data, action.data),
        },
      };

    case types.SET_ACTIVE_BANNER:
      return {
        ...state,
        listBanner: {
          ...state.listBanner,
          loading: false,
          error: "",
          data: activeItem(state.listBanner.data, action.data),
        },
      };
    default:
      return state;
  }
};
export default audiobook;
