import { OPTIONS_LIMIT } from "./data.js";
export const URL_COMPANIES = `/companies?is_active=true&_limit=${OPTIONS_LIMIT}%(params)s`;

export const types = {
  GET_OPTION_COMPANY_LOADING: "GET_OPTION_COMPANY_LOADING",
  GET_OPTION_COMPANY_SUCCESS: "GET_OPTION_COMPANY_SUCCESS",
  GET_OPTION_COMPANY_FAILED: "GET_OPTION_COMPANY_FAILED",

  MORE_OPTION_COMPANY_LOADING: "MORE_OPTION_COMPANY_LOADING",
  MORE_OPTION_COMPANY_SUCCESS: "MORE_OPTION_COMPANY_SUCCESS",
  MORE_OPTION_COMPANY_FAILED: "MORE_OPTION_COMPANY_FAILED",
};
