import { OPTIONS_LIMIT } from "./data.js";

export const URL_PROVINCE = `/countries/%(id)s/provinces?_limit=${OPTIONS_LIMIT}%(params)s`;
export const URL_CITY = `/provinces/%(id)s/cities?_limit=${OPTIONS_LIMIT}%(params)s`;
export const URL_AREA = `/cities/%(id)s/areas?_limit=${OPTIONS_LIMIT}%(params)s`;

export const types = {
  GET_OPTION_PROVINCE_LOADING: "GET_OPTION_PROVINCE_LOADING",
  GET_OPTION_PROVINCE_SUCCESS: "GET_OPTION_PROVINCE_SUCCESS",
  GET_OPTION_PROVINCE_FAILED: "GET_OPTION_PROVINCE_FAILED",

  MORE_OPTION_PROVINCE_LOADING: "MORE_OPTION_PROVINCE_LOADING",
  MORE_OPTION_PROVINCE_SUCCESS: "MORE_OPTION_PROVINCE_SUCCESS",
  MORE_OPTION_PROVINCE_FAILED: "MORE_OPTION_PROVINCE_FAILED",

  GET_OPTION_CITY_LOADING: "GET_OPTION_CITY_LOADING",
  GET_OPTION_CITY_SUCCESS: "GET_OPTION_CITY_SUCCESS",
  GET_OPTION_CITY_FAILED: "GET_OPTION_CITY_FAILED",

  MORE_OPTION_CITY_LOADING: "MORE_OPTION_CITY_LOADING",
  MORE_OPTION_CITY_SUCCESS: "MORE_OPTION_CITY_SUCCESS",
  MORE_OPTION_CITY_FAILED: "MORE_OPTION_CITY_FAILED",

  GET_OPTION_AREA_LOADING: "GET_OPTION_AREA_LOADING",
  GET_OPTION_AREA_SUCCESS: "GET_OPTION_AREA_SUCCESS",
  GET_OPTION_AREA_FAILED: "GET_OPTION_AREA_FAILED",

  MORE_OPTION_AREA_LOADING: "MORE_OPTION_AREA_LOADING",
  MORE_OPTION_AREA_SUCCESS: "MORE_OPTION_AREA_SUCCESS",
  MORE_OPTION_AREA_FAILED: "MORE_OPTION_AREA_FAILED",

  RESET_AREA: "RESET_AREA",
};
