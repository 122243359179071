import { CARD_LIMIT } from "./data.js";
export const URL_GET_LUCKY = `/lucky-spins?_limit=${CARD_LIMIT}%(params)s`;
export const URL_CREATE_LUCKY = `/lucky-spins`;
export const URL_LUCKY = `/lucky-spins/%(id)s`;
export const URL_GET_REWARDS = `/lucky-spins/%(id)s/lucky-spin-rewards?_limit=99`;
export const URL_GET_LIST_SPIN = `/lucky-spins/%(id)s/lucky-spin-rewards?_limit=99`;
export const URL_WINNER_SPIN = `/lucky-spins/%(id)s/lucky-spin-users`;
// export const URL_GET_REWARDS = `/lucky-spins?status[]=claim&status[]=delivery_process&status[]=done&_limit=${CARD_LIMIT}%(params)s`;

export const types = {
  GET_LUCKY_LOADING: "GET_LUCKY_LOADING",
  GET_LUCKY_SUCCESS: "GET_LUCKY_SUCCESS",
  GET_LUCKY_FAILED: "GET_LUCKY_FAILED",

  GET_LIST_SPIN_LOADING: "GET_LIST_SPIN_LOADING",
  GET_LIST_SPIN_SUCCESS: "GET_LIST_SPIN_SUCCESS",
  GET_LIST_SPIN_FAILED: "GET_LIST_SPIN_FAILED",

  EMPTY_LIST_SPIN: "EMPTY_LIST_SPIN",

  GET_REWARDS_LOADING: "GET_REWARDS_LOADING",
  GET_REWARDS_SUCCESS: "GET_REWARDS_SUCCESS",
  GET_REWARDS_FAILED: "GET_REWARDS_FAILED",

  CREATE_LUCKY_LOADING: "CREATE_LUCKY_LOADING",
  CREATE_LUCKY_SUCCESS: "CREATE_LUCKY_SUCCESS",
  CREATE_LUCKY_FAILED: "CREATE_LUCKY_FAILED",

  UPDATE_LUCKY_LOADING: "UPDATE_LUCKY_LOADING",
  UPDATE_LUCKY_SUCCESS: "UPDATE_LUCKY_SUCCESS",
  UPDATE_LUCKY_FAILED: "UPDATE_LUCKY_FAILED",

  SHOW_LUCKY_LOADING: "SHOW_LUCKY_LOADING",
  SHOW_LUCKY_SUCCESS: "SHOW_LUCKY_SUCCESS",
  SHOW_LUCKY_FAILED: "SHOW_LUCKY_FAILED",

  RESET_UPLOAD_LUCKY: "RESET_UPLOAD_LUCKY",

  DELETE_LUCKY_LOADING: "DELETE_LUCKY_LOADING",
  DELETE_LUCKY_SUCCESS: "DELETE_LUCKY_SUCCESS",
  DELETE_LUCKY_FAILED: "DELETE_LUCKY_FAILED",
};
