import { CARD_LIMIT } from "./data.js";
export const URL_GET_USERS = `/users?_limit=${CARD_LIMIT}%(params)s`;
export const URL_USERS = "/users/%(id)s";
export const URL_CREATE_USER = "/users";
export const URL_GET_USERS_REWARD = `/module/user/reward-participants?_limit=${CARD_LIMIT}%(params)s`
export const URL_GET_USER_CPLUS = `/cplus-members?_limit=${CARD_LIMIT}%(params)s`;
export const URL_CREATE_USER_CPLUS = "/cplus-members";
export const URL_USERS_CPLUS = "/cplus-members/%(id)s";
export const URL_USERS_CPLUS_CREATE_AND_EDIT = `/users/cplus-members/?_limit=${CARD_LIMIT}%(params)s`;

export const URL_GET_CAREER = `/career-levels?_limit=${CARD_LIMIT}%(params)s`;
export const URL_CAREER = "/career-levels/%(id)s";
export const URL_CREATE_CAREER = "/career-levels";

export const types = {
  GET_USER_LOADING: "GET_USER_LOADING",
  GET_USER_SUCCESS: "GET_USER_SUCCESS",
  GET_USER_FAILED: "GET_USER_FAILED",

  GET_USER_CPLUS_LOADING: "GET_USER_CPLUS_LOADING",
  GET_USER_CPLUS_SUCCESS: "GET_USER_CPLUS_SUCCESS",
  GET_USER_CPLUS_FAILED: "GET_USER_CPLUS_FAILED",

  GET_USER_CPLUS_FOR_CREATE_AND_EDIT_LOADING: "GET_USER_CPLUS_FOR_CREATE_AND_EDIT_LOADING",
  GET_USER_CPLUS_FOR_CREATE_AND_EDIT_SUCCESS: "GET_USER_CPLUS_FOR_CREATE_AND_EDIT_SUCCESS",
  GET_USER_CPLUS_FOR_CREATE_AND_EDIT_FAILED: "GET_USER_CPLUS_FOR_CREATE_AND_EDIT_FAILED",
  RESET_USER_CPLUS_FOR_CREATE_AND_EDIT: "RESET_USER_CPLUS_FOR_CREATE_AND_EDIT",
  MORE_USER_CPLUS_FOR_CREATE_AND_EDIT_LOADING: "MORE_USER_CPLUS_FOR_CREATE_AND_EDIT_LOADING",
  MORE_USER_CPLUS_FOR_CREATE_AND_EDIT_SUCCESS: "MORE_USER_CPLUS_FOR_CREATE_AND_EDIT_SUCCESS",
  MORE_USER_CPLUS_FOR_CREATE_AND_EDIT_FAILED: "MORE_USER_CPLUS_FOR_CREATE_AND_EDIT_FAILED",

  GET_CAREER_LOADING: "GET_CAREER_LOADING",
  GET_CAREER_SUCCESS: "GET_CAREER_SUCCESS",
  GET_CAREER_FAILED: "GET_CAREER_FAILED",

  DELETE_USER_LOADING: "DELETE_USER_LOADING",
  DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
  DELETE_USER_FAILED: "DELETE_USER_FAILED",

  DELETE_USER_CPLUS_LOADING: "DELETE_USER_CPLUS_LOADING",
  DELETE_USER_CPLUS_SUCCESS: "DELETE_USER_CPLUS_SUCCESS",
  DELETE_USER_CPLUS_FAILED: "DELETE_USER_CPLUS_FAILED",

  DELETE_CAREER_LOADING: "DELETE_CAREER_LOADING",
  DELETE_CAREER_SUCCESS: "DELETE_CAREER_SUCCESS",
  DELETE_CAREER_FAILED: "DELETE_CAREER_FAILED",

  CREATE_USER_LOADING: "CREATE_USER_LOADING",
  CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
  CREATE_USER_FAILED: "CREATE_USER_FAILED",

  CREATE_USER_CPLUS_LOADING: "CREATE_USER_CPLUS_LOADING",
  CREATE_USER_CPLUS_SUCCESS: "CREATE_USER_CPLUS_SUCCESS",
  CREATE_USER_CPLUS_FAILED: "CREATE_USER_CPLUS_FAILED",

  CREATE_CAREER_LOADING: "CREATE_CAREER_LOADING",
  CREATE_CAREER_SUCCESS: "CREATE_CAREER_SUCCESS",
  CREATE_CAREER_FAILED: "CREATE_CAREER_FAILED",

  UPDATE_USER_LOADING: "UPDATE_USER_LOADING",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_FAILED: "UPDATE_USER_FAILED",

  UPDATE_USER_CPLUS_LOADING: "UPDATE_USER_CPLUS_LOADING",
  UPDATE_USER_CPLUS_SUCCESS: "UPDATE_USER_CPLUS_SUCCESS",
  UPDATE_USER_CPLUS_FAILED: "UPDATE_USER_CPLUS_FAILED",

  UPDATE_CAREER_LOADING: "UPDATE_CAREER_LOADING",
  UPDATE_CAREER_SUCCESS: "UPDATE_CAREER_SUCCESS",
  UPDATE_CAREER_FAILED: "UPDATE_CAREER_FAILED",

  SHOW_USER_LOADING: "SHOW_USER_LOADING",
  SHOW_USER_SUCCESS: "SHOW_USER_SUCCESS",
  SHOW_USER_FAILED: "SHOW_USER_FAILED",

  SHOW_USER_CPLUS_LOADING: "SHOW_USER_CPLUS_LOADING",
  SHOW_USER_CPLUS_SUCCESS: "SHOW_USER_CPLUS_SUCCESS",
  SHOW_USER_CPLUS_FAILED: "SHOW_USER_CPLUS_FAILED",

  SHOW_CAREER_LOADING: "SHOW_CAREER_LOADING",
  SHOW_CAREER_SUCCESS: "SHOW_CAREER_SUCCESS",
  SHOW_CAREER_FAILED: "SHOW_CAREER_FAILED",

  RESET_UPLOAD_CAREER: "RESET_UPLOAD_CAREER",
  RESET_UPLOAD_ERROR: "RESET_UPLOAD_ERROR",

  RESET_USER: "RESET_USER",

  FIND_USER: "FIND_USER",
  FIND_USER_REWARD: "FIND_USER_REWARD",

  MORE_OPTION_USER_LOADING: "MORE_OPTION_USER_LOADING",
  MORE_OPTION_USER_SUCCESS: "MORE_OPTION_USER_SUCCESS",
  MORE_OPTION_USER_FAILED: "MORE_OPTION_USER_FAILED",
};
