import { types } from "../constants/event";

const initialState = {
  list: {
    data: [],
    loading: false,
    error: null,
    page: 1,
    totalPage: null,
  },
  dprizeParticipant: {
    data: [],
    loading: false,
    error: null,
  },
  onWinner: {
    loading: false,
    error: null,
  },
  onDelete: {
    loading: false,
    error: null,
  },
  onCreate: {
    loading: false,
    error: null,
  },
  detailList: {
    data: {},
    loading: false,
    error: null,
  },
  onUpdate: {
    loading: false,
    error: null,
  },
  participant: {
    data: [],
    loading: false,
    error: null,
    page: 1,
    totalPage: null,
    totalData: null,
    isLoadMore: false,
  },
};

const event = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_EVENT_LOADING:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
      };
    case types.GET_EVENT_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: "",
          data: action.data,
          totalPage: parseInt(action.totalPage) || state.list.totalPage,
          page: action.page,
        },
      };
    case types.GET_EVENT_FAILED:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: action.message,
        },
      };
    case types.GET_PARTICIPANT_LOADING:
      return {
        ...state,
        participant: {
          ...state.participant,
          loading: true,
        },
      };
    case types.GET_PARTICIPANT_SUCCESS:
      return {
        ...state,
        participant: {
          ...state.participant,
          loading: false,
          data: action.data,
          page: 1,
          totalPage: action.totalPage,
          totalData: action.totalData,
        },
      };
    case types.GET_PARTICIPANT_FAILED:
      return {
        ...state,
        participant: {
          ...state.participant,
          loading: false,
          error: action.message,
        },
      };

    case types.MORE_PARTICIPANT_LOADING:
      return {
        ...state,
        participant: {
          ...state.participant,
          isLoadMore: true,
        },
      };
    case types.MORE_PARTICIPANT_SUCCESS:
      return {
        ...state,
        participant: {
          ...state.participant,
          isLoadMore: false,
          data: [...state.participant.data, ...action.data],
          page: action.page,
        },
      };
    case types.MORE_PARTICIPANT_FAILED:
      return {
        ...state,
        participant: {
          ...state.participant,
          isLoadMore: false,
          error: action.message,
        },
      };
    case types.DELETE_EVENT_LOADING:
      return {
        ...state,
        onDelete: {
          ...state.onDelete,
          loading: true,
        },
      };
    case types.DELETE_EVENT_SUCCESS:
      return {
        ...state,
        onDelete: {
          ...state.onDelete,
          loading: false,
        },
      };
    case types.DELETE_EVENT_FAILED:
      return {
        ...state,
        onDelete: {
          ...state.onDelete,
          loading: false,
          error: action.message,
        },
      };
    case types.CREATE_EVENT_LOADING:
      return {
        ...state,
        onCreate: {
          ...state.onCreate,
          loading: true,
        },
      };
    case types.CREATE_EVENT_SUCCESS:
      return {
        ...state,
        onCreate: {
          ...state.onCreate,
          loading: false,
        },
      };
    case types.CREATE_EVENT_FAILED:
      return {
        ...state,
        onCreate: {
          ...state.onCreate,
          loading: false,
          error: action.message,
        },
      };

    case types.UPDATE_EVENT_LOADING:
      return {
        ...state,
        onUpdate: {
          ...state.onUpdate,
          loading: true,
        },
      };
    case types.UPDATE_EVENT_SUCCESS:
      return {
        ...state,
        onUpdate: {
          ...state.onUpdate,
          loading: false,
        },
      };
    case types.UPDATE_EVENT_FAILED:
      return {
        ...state,
        onUpdate: {
          ...state.onUpdate,
          loading: false,
          error: action.message,
        },
      };

    case types.SHOW_EVENT_LOADING:
      return {
        ...state,
        detailList: {
          ...state.detailList,
          loading: true,
        },
      };
    case types.SHOW_EVENT_SUCCESS:
      return {
        ...state,
        detailList: {
          ...state.detailList,
          loading: false,
          data: action.data,
          error: null,
        },
      };
    case types.SHOW_EVENT_FAILED:
      return {
        ...state,
        detailList: {
          ...state.detailList,
          loading: false,
          error: action.message,
        },
      };

    case types.DOORPRIZE_PARTICIPANT_LOADING:
      return {
        ...state,
        dprizeParticipant: {
          ...state.dprizeParticipant,
          loading: true,
        },
      };
    case types.DOORPRIZE_PARTICIPANT_SUCCESS:
      return {
        ...state,
        dprizeParticipant: {
          ...state.dprizeParticipant,
          loading: false,
          data: action.data,
          error: null,
        },
      };
    case types.DOORPRIZE_PARTICIPANT_FAILED:
      return {
        ...state,
        dprizeParticipant: {
          ...state.dprizeParticipant,
          loading: false,
          error: action.message,
        },
      };

    case types.WINNER_DOORPRIZE_LOADING:
      return {
        ...state,
        onWinner: {
          ...state.onWinner,
          loading: true,
        },
      };
    case types.WINNER_DOORPRIZE_SUCCESS:
      return {
        ...state,
        onWinner: {
          ...state.onWinner,
          loading: false,
          error: null,
        },
      };
    case types.WINNER_DOORPRIZE_FAILED:
      return {
        ...state,
        onWinner: {
          ...state.onWinner,
          loading: false,
          error: action.message,
        },
      };

    case types.RESET_UPLOAD_EVENT:
      return {
        ...state,
        onCreate: {
          ...state.onCreate,
          error: null,
        },
        onUpdate: {
          ...state.onUpdate,
          error: null,
        },
      };
    default:
      return state;
  }
};
export default event;
