import { CARD_LIMIT } from "./data.js";
export const URL_ADMIN = `/admins?_limit=${CARD_LIMIT}%(params)s`;
export const URL_ADMIN_DETAIL = `/admins/%(id)s`;
export const URL_CREATE_ADMIN = "/admins";
export const types = {
  GET_ADMIN_LOADING: "GET_ADMIN_LOADING",
  GET_ADMIN_SUCCESS: "GET_ADMIN_SUCCESS",
  GET_ADMIN_FAILED: "GET_ADMIN_FAILED",

  DELETE_ADMIN_LOADING: "DELETE_ADMIN_LOADING",
  DELETE_ADMIN_SUCCESS: "DELETE_ADMIN_SUCCESS",
  DELETE_ADMIN_FAILED: "DELETE_ADMIN_FAILED",

  CREATE_ADMIN_LOADING: "CREATE_ADMIN_LOADING",
  CREATE_ADMIN_SUCCESS: "CREATE_ADMIN_SUCCESS",
  CREATE_ADMIN_FAILED: "CREATE_ADMIN_FAILED",

  UPDATE_ADMIN_LOADING: "UPDATE_ADMIN_LOADING",
  UPDATE_ADMIN_SUCCESS: "UPDATE_ADMIN_SUCCESS",
  UPDATE_ADMIN_FAILED: "UPDATE_ADMIN_FAILED",

  SHOW_ADMIN_LOADING: "SHOW_ADMIN_LOADING",
  SHOW_ADMIN_SUCCESS: "SHOW_ADMIN_SUCCESS",
  SHOW_ADMIN_FAILED: "SHOW_ADMIN_FAILED",

  RESET_UPLOAD_ADMIN: "RESET_UPLOAD_ADMIN",
};
