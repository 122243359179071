import { CARD_LIMIT } from "./data.js";
export const URL_GET_REWARD = `/rewards?_limit=${CARD_LIMIT}%(params)s`;
export const URL_CREATE_REWARD = `/rewards`;
export const URL_REWARD = `/rewards/%(id)s`;
export const URL_GET_KLAIM = `/rewards?status[]=claim&status[]=delivery_process&status[]=done&_limit=${CARD_LIMIT}%(params)s`;

export const types = {
  GET_REWARD_LOADING: "GET_REWARD_LOADING",
  GET_REWARD_SUCCESS: "GET_REWARD_SUCCESS",
  GET_REWARD_FAILED: "GET_REWARD_FAILED",

  GET_KLAIM_LOADING: "GET_KLAIM_LOADING",
  GET_KLAIM_SUCCESS: "GET_KLAIM_SUCCESS",
  GET_KLAIM_FAILED: "GET_KLAIM_FAILED",

  CREATE_REWARD_LOADING: "CREATE_REWARD_LOADING",
  CREATE_REWARD_SUCCESS: "CREATE_REWARD_SUCCESS",
  CREATE_REWARD_FAILED: "CREATE_REWARD_FAILED",

  UPDATE_REWARD_LOADING: "UPDATE_REWARD_LOADING",
  UPDATE_REWARD_SUCCESS: "UPDATE_REWARD_SUCCESS",
  UPDATE_REWARD_FAILED: "UPDATE_REWARD_FAILED",

  SHOW_REWARD_LOADING: "SHOW_REWARD_LOADING",
  SHOW_REWARD_SUCCESS: "SHOW_REWARD_SUCCESS",
  SHOW_REWARD_FAILED: "SHOW_REWARD_FAILED",

  RESET_UPLOAD_REWARD: "RESET_UPLOAD_REWARD",

  DELETE_REWARD_LOADING: "DELETE_REWARD_LOADING",
  DELETE_REWARD_SUCCESS: "DELETE_REWARD_SUCCESS",
  DELETE_REWARD_FAILED: "DELETE_REWARD_FAILED",
};
